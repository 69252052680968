import React, { useState } from "react";
import PaymentsTable from "./PaymentsTable";

const Payments = () => {
  const [newPayment, setNewPayment] = useState();

  return (
    <section className="flex flex-col gap-[26px] pt-[26px]">
      <h3 className="page-heading">Payments</h3>
      <PaymentsTable newPayment={newPayment} setNewPayment={setNewPayment} />
    </section>
  );
};

export default Payments;
