import React, { useState } from "react";
import pdf from "../../assets/icons/pdf.svg";
import print from "../../assets/icons/print.svg";
import { getExportSalesDate } from "../../api/AppManagement";

const CustomModalContent = ({ title }) => {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [loading, setLoading] = useState(false);

  const exportSalesDateHandler = async () => {
    setLoading(true);
    try {
      const { data } = await getExportSalesDate(startDate, endDate);
      console.log(data);
      setLoading(false);
    } catch (error) {
      console.error("Error in API call:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <p className="text-lg font-semibold leading-6 text-[#58626C] capitalize">
        {title}
      </p>
      <div className="w-full flex justify-center items-start gap-4 max-sm:flex-col">
        <div className="flex flex-col gap-1 items-start w-full ">
          <p className="text-[13px] text-[#58626C] capitalize">From</p>
          <input
            type="date"
            className="w-full h-[40px] border border-[#DBDADE] py-[7px] px-[14px] rounded-md focus:outline-none"
            onChange={(e) => setStartDate(e.target.value)}
          />
        </div>
        <div className="flex flex-col gap-1 items-start w-full">
          <p className="text-[13px] text-[#58626C] capitalize">To</p>
          <input
            type="date"
            className="w-full h-[40px] border border-[#DBDADE] py-[7px] px-[14px] rounded-md focus:outline-none"
            onChange={(e) => setEndDate(e.target.value)}
          />
        </div>
      </div>
      <div className="flex justify-center items-center gap-4">
        <button
          className="flex flex-col items-center justify-between border-[0.5px] border-[#EA5455] rounded-xl text-[#EA5455] px-[15px] py-[10px] gap-2"
          onClick={exportSalesDateHandler}
          disabled={loading}
        >
          {loading ? (
            <span>Loading...</span>
          ) : (
            <>
              <img src={pdf} alt="pdf" />
              <p className="text-[11px] font-bold leading-[13px] text-[#EA5455] capitalize">
                PDF
              </p>
            </>
          )}
        </button>
        <button className="flex flex-col items-center justify-between border-[0.5px] border-[#FFD700] rounded-xl text-[#FFD700] px-[15px] py-[10px] gap-2">
          <img src={print} alt="print" />
          <p className="text-[11px] font-bold leading-[13px] text-[#FFD700] capitalize">
            Print
          </p>
        </button>
      </div>
    </>
  );
};

export default CustomModalContent;
