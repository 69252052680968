import React, { useState } from "react";
import { motion } from "framer-motion";
import MenuItem from "./MenuItem";
import DashboardIcon from "./icons/DashboardIcon";
import logo from "../../assets/icons/logo.svg";
import menu from "../../assets/icons/circle-dot.svg";
import { MANAGE_APP, MANAGE_DASHBOARD } from "../../util/menu-links";
import LogoutIcon from "./icons/LogoutIcon";
import { useNavigate } from "react-router";

const SideMenu = () => {
  const navigate = useNavigate();
  const [collapse, setCollapse] = useState(false);
  const toggleMenu = () => {
    setCollapse((prev) => !prev);
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
  };

  return (
    <motion.div
      animate={{ width: collapse ? 84 : 260 }}
      transition={{ duration: 0.3, type: "tween" }}
      className={`max-sm:hidden w-[260px] h-screen scrollbar sticky top-0 left-0 bg-white shadow-gray-sm px-[22px] z-50 overflow-auto scroll group ${
        collapse && "collapse-menu"
      }`}
    >
      <div className="flex py-[20px] justify-between group-[.collapse-menu]:justify-center ">
        <div className="flex gap-[10px] items-center">
          <img
            src={logo}
            alt="logo"
            className="group-[.collapse-menu]:cursor-pointer min-w-[50px]"
            onClick={() => {
              if (collapse) {
                toggleMenu();
              }
            }}
          />
          <motion.h1
            animate={{ display: collapse ? "none" : "block" }}
            transition={{ duration: 0.15 }}
            className="font-bold text-md uppercase text-[#4b465c]"
          >
            Dafatar
          </motion.h1>
        </div>
        <img
          src={menu}
          alt="menu"
          className="cursor-pointer group-[.collapse-menu]:hidden"
          onClick={toggleMenu}
        />
      </div>
      <MenuItem
        name="Dashboard"
        url="/"
        icon={<DashboardIcon color="#4B465C" />}
        activeIcon={<DashboardIcon color="#fff" />}
        collapse={collapse}
      />
      <p className="mb-[6px] text-[11px] ms-[24px] text-[#4B465C] group-[.collapse-menu]:hidden">
        Manage Mobile App
      </p>
      {MANAGE_APP.map((item) => (
        <MenuItem
          key={item.name}
          name={item.name}
          url={item.url}
          icon={item.icon}
          activeIcon={item.activeIcon}
          collapse={collapse}
        />
      ))}
      <p className="my-[6px] text-[11px] ms-[24px] text-[#4B465C] group-[.collapse-menu]:hidden">
        Manage Daftar Dhabi
      </p>
      {MANAGE_DASHBOARD.map((item) => (
        <MenuItem
          key={item.name}
          name={item.name}
          url={item.url}
          icon={item.icon}
          activeIcon={item.activeIcon}
          collapse={collapse}
        />
      ))}
      <p className="my-[6px] text-[11px] ms-[24px] text-[#4B465C] group-[.collapse-menu]:hidden">
        Settings
      </p>
      <div
        className="flex gap-[10px] group-[.collapse-menu]:justify-center items-center px-[16px] py-[9px] cursor-pointer"
        onClick={handleLogout}
      >
        <LogoutIcon />
        <motion.h1
          animate={{ display: collapse ? "none" : "block" }}
          transition={{ duration: 0.15 }}
          className={`font-semibold text-xs leading-[22px] `}
        >
          Logout
        </motion.h1>
      </div>
    </motion.div>
  );
};

export default SideMenu;
