import React, { useState } from "react";
import Input from "../ui/Input";
import { Button } from "@nextui-org/react";
import { updatePayment } from "../../api/DashboardManagement";

const EditPaymentModal = ({
  closeModal,
  paymentData,
  toggleEditing,
  isEditing,
  onUpdatePayment
}) => {
  const [name, setName] = useState(paymentData.attribute.payment_name);

  const updatePaymentHandler = async () => {
    const { data } = await updatePayment(paymentData.id, name);
    console.log(data);
    onUpdatePayment(data.data)
    closeModal();
  };

  return (
    <div className="flex flex-col gap-4">
      <Input
        title="Payment Name"
        type="text"
        name="payment"
        placeholder="Vodafone Cash"
        value={name}
        onChange={(e) => setName(e.target.value)}
        disabled={!isEditing}
      />
      <div className="flex items-start gap-4 mt-[10px]">
        {isEditing ? (
          <Button
            size="sm"
            className="w-max bg-primary-500 text-white text-sm rounded-md py-[10px] px-[20px] shadow-[0px_2px_4px_0px_rgba(165, 163, 174, 0.30)] capitalize"
            onClick={updatePaymentHandler}
          >
            Save
          </Button>
        ) : (
          <Button
            size="sm"
            className="w-max bg-primary-500 text-white text-sm rounded-md py-[10px] px-[20px] shadow-[0px_2px_4px_0px_rgba(165, 163, 174, 0.30)] capitalize"
            onClick={toggleEditing}
          >
            Edit
          </Button>
        )}

        <Button
          size="sm"
          className="w-max bg-secondary-200 text-secondary-500 text-sm rounded-md py-[10px] px-[20px] shadow-[0px_2px_4px_0px_rgba(165, 163, 174, 0.30)] capitalize"
          onClick={closeModal}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default EditPaymentModal;
