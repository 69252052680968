import React, { useState, useMemo } from "react";
import {
  Chip,
  DropdownItem,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "@nextui-org/react";
import mail from "../../assets/icons/mail.svg";
import eye from "../../assets/icons/eye.svg";
import edit from "../../assets/icons/edit.svg";
import trash from "../../assets/icons/trash.svg";
import status from '../../assets/icons/toggle-status.svg'
import DropdownMenus from "../ui/DropdownMenus";
import { formatDate } from "../../helpers/format-date";
import Modal from "../ui/Modal";
import EditPaymentModal from "./EditPaymentModal";
import {
  changeStatusPayment,
  deletePayment,
} from "../../api/DashboardManagement";

const columnsHeaders = [
  { id: "id", name: "ID", sortable: true },
  { id: "payment_name", name: "Payment Name", sortable: true },
  { id: "date", name: "Created Date", sortable: true },
  { id: "status", name: "Status", sortable: false },
  { id: "actions", name: "Actions", sortable: false },
];

const PaymentsSingleTable = ({
  payments,
  onDeletePayment,
  onUpdatePayment,
}) => {
  const [sortDescriptor, setSortDescriptor] = useState({
    column: "id",
    direction: "ascending",
  });

  const sortedItems = useMemo(() => {
    return [...payments].sort((a, b) => {
      const first = a[sortDescriptor.column];
      const second = b[sortDescriptor.column];
      const cmp = first < second ? -1 : first > second ? 1 : 0;
      return sortDescriptor.direction === "descending" ? -cmp : cmp;
    });
  }, [payments, sortDescriptor]);

  const [isEditing, setIsEditing] = useState();

  const deleteHandler = async (id) => {
    await deletePayment(id);
    onDeletePayment(id);
  };

  const toggleEditing = () => {
    setIsEditing(!isEditing);
  };

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(null);

  const openEditModal = (payment) => {
    setSelectedPayment(payment);
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setSelectedPayment(null);
    toggleEditing();
  };

  const toggleStatus = async (id, currentStatus) => {
    const newStatus = currentStatus == 1 ? 0 : 1;
    const { data } = await changeStatusPayment(id, newStatus);
    console.log(data);
    onUpdatePayment(data.data);
  };
  return (
    <>
      <Table
        aria-label="stores_table"
        sortDescriptor={sortDescriptor}
        onSortChange={setSortDescriptor}
        classNames={{
          wrapper: "rounded-[0] px-0 py-0",
          th: "uppercase bg-transparent px-6 py-4 border-b",
          td: "py-8 px-6",
        }}
      >
        <TableHeader columns={columnsHeaders}>
          {(column) => (
            <TableColumn key={column.id} allowsSorting={column.sortable}>
              {column.name}
            </TableColumn>
          )}
        </TableHeader>
        <TableBody items={sortedItems} emptyContent={"No stores found"}>
          {(item) => (
            <TableRow key={item.id} className="border-b">
              <TableCell>{item.id}</TableCell>
              <TableCell className="md:w-[500px]">
                {item.attribute.payment_name}
              </TableCell>
              <TableCell>{formatDate(item.attribute.created_at)}</TableCell>
              <TableCell>
                <Chip
                  radius="sm"
                  className={`p-[5px 10px] text-sm font-semibold capitalize ${item.attribute.status == 1
                      ? "bg-success-200 text-success-500"
                      : "bg-secondary-200 text-secondary-500"
                    }`}
                >
                  {item.attribute.status == 1 ? "active" : "Inactive"}
                </Chip>
              </TableCell>
              <TableCell>
                <div className="flex gap-2 items-center w-fit">
                  <img src={mail} alt="mail" />
                  <img
                    src={eye}
                    alt="eye"
                    className="cursor-pointer"
                    onClick={() => {
                      openEditModal(item);
                      setIsEditing(false);
                    }}
                  />
                  <DropdownMenus>
                    <DropdownItem
                      aria-label="edit"
                      key="edit"
                      startContent={<img src={edit} alt="dots" />}
                      onClick={() => {
                        openEditModal(item);
                        setIsEditing(true);
                      }}
                    >
                      Edit
                    </DropdownItem>
                    <DropdownItem
                      aria-label="delete"
                      key="delete"
                      className="text-danger"
                      startContent={
                        <img src={trash} alt="dots" className="text-danger" />
                      }
                      onClick={() => deleteHandler(item.id)}
                    >
                      Delete
                    </DropdownItem>
                    <DropdownItem
                      aria-label="status"
                      key="status"
                      className="text-danger"
                      startContent={
                        <img src={status} alt="toggle" />
                      }
                      onClick={() =>
                        toggleStatus(item.id, item.attribute.status)
                      }
                    >
                      Status
                    </DropdownItem>
                  </DropdownMenus>
                </div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Modal
        isOpen={isEditModalOpen}
        onClose={closeEditModal}
        title={isEditing ? "Edit Package" : "Preview Package"}
      >
        {selectedPayment && (
          <EditPaymentModal
            closeModal={closeEditModal}
            paymentData={selectedPayment}
            toggleEditing={toggleEditing}
            isEditing={isEditing}
            onUpdatePayment={onUpdatePayment}
          />
        )}
      </Modal>
    </>
  );
};

export default PaymentsSingleTable;
