import React, { useState } from "react";
import { Checkbox } from "@nextui-org/react";

const BorderedCheckbox = ({
  id,
  label,
  value,
  onChangeHandler,
  isChecked,
  isDisabled,
}) => {
  const [checked, setChecked] = useState(isChecked);
  return (
    <div
      className={`px-4 py-2 rounded-md border ${
        checked ? "border-primary-500" : "border-[#DBDADE]"
      }`}
    >
      <Checkbox
        id={id}
        value={value}
        color="default"
        isSelected={checked}
        onValueChange={setChecked}
        isDisabled={isDisabled}
        onChange={onChangeHandler}
        classNames={{
          wrapper: [
            "group-data-[selected=true]:after:bg-primary-500",
            "group-data-[disabled=true]:bg-[#4B465C]",
            // group-data-[disabled=true]:before:border-[#4B465C]
          ],
          icon: ["group-data-[selected=true]:text-white"],
        }}
      >
        {label}
      </Checkbox>
    </div>
  );
};

export default BorderedCheckbox;
