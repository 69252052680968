import React, { useEffect, useState } from "react";
import dots from "../../assets/icons/dots-vertical.svg";
import { Bar, BarChart, Cell, ResponsiveContainer, XAxis } from "recharts";
import goldenDollar from "../../assets/icons/goldenDollar.svg";
import { getGraphData, getTotalIncomeYear } from "../../api/AppManagement";
import Loader from "../ui/Loader";


const Graph = () => {
  const [activeIndex, setActiveIndex] = useState(new Date().getMonth());

  const [monthBilling, setMonthBilling] = useState();
  const [loading, setLoading] = useState(true);


  const [totalIncomeYear, setTotalIncomeYear] = useState();

  useEffect(() => {
    const getMonthBillingData = async () => {
      const { data } = await getGraphData(activeIndex + 1);
      setMonthBilling(data);
      setLoading(false);
      console.log(data);
    };
    getMonthBillingData();
  }, [activeIndex]);

  useEffect(() => {
    const getTotalIncomeYearData = async () => {
      const { data } = await getTotalIncomeYear();
      setLoading(false);
      setTotalIncomeYear(data);
      console.log(data);
    };
    getTotalIncomeYearData();
  }, []);

  const handleClick = (data, index) => {
    setActiveIndex(index);
    console.log(data, index);
  };

  return (
    <div className="flex flex-col p-6 rounded-md shadow-gray-card bg-white">
      <div className="flex items-start justify-between">
        <div className="flex flex-col items-start">
          <h3 className="text-[18px] font-semibold leading-6 text-[#4B465C] capitalize">
            Earning Reports
          </h3>
          <p className="text-[13px] leading-5 text-[#a5a2ad] capitalize">
            Monthly Earnings Overview
          </p>
        </div>
        <img src={dots} alt="dots" />
      </div>

      {loading ? (
        <Loader />
      ) : (
        <div className="flex flex-col gap-[24px]">
          <div className="mt-[37px] flex items-start max-lg:flex-col">
            <div className="flex flex-col items-start justify-center gap-3 w-[200px]">
              <h2 className="text-[38px] font-semibold leading-[52px] text-[#4B465C] capitalize h-[100px] max-lg:h-full">
                EGP {monthBilling[activeIndex]?.total_billing}
              </h2>
              <p className="text-[13px] leading-5 text-[#4B465C] capitalize">
                You informed of this month compared to last week
              </p>
            </div>

            <ResponsiveContainer width={"100%"} height={160}>
              <BarChart
                width={500}
                height={160}
                data={monthBilling}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <XAxis
                  dataKey="month"
                  textDecoration="#4B465C"
                  tickLine
                  strokeWidth={0}
                />
                <Bar dataKey="total_billing" onClick={handleClick}>
                  {monthBilling.map((entry, index) => (
                    <Cell
                      cursor="pointer"
                      fill={index === activeIndex ? "#FFD700" : "#7367F029"}
                      key={`cell-${index}`}
                    />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>

          <div className="flex flex-col items-start justify-center gap-[10px] py-4 px-5 rounded-md border border-[#DBDADE]">
            <div className="flex  items-center gap-2">
              <img
                src={goldenDollar}
                alt="dollar"
                className="w-[20px] h-[20px] rounded-md p-1 bg-[#f1effd]"
              />
              <p className="text-[15px] leading-[21px] font-semibold text-[#4B465C] capitalize">
                total income this year
              </p>
            </div>
            {loading ? (
              <Loader />
            ) : (
              <p className="text-[22px] leading-[30px] font-semibold text-[#4B465C] capitalize">
                EGP {totalIncomeYear?.total_income_billing}
              </p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Graph;
