import React from "react";
import InvoiceTable from "../invoice/InvoiceTable";
import Graph from "./Graph";
import PaymentMethods from "./PaymentMethods";
import Statistics from "./Statistics";
import Wallets from "./Wallets";

const Dashboard = () => {
  return (
    <section className="flex flex-col gap-[26px] pt-[26px]">
      <Statistics />
      <Wallets />

      <div className="flex gap-[26px] max-lg:flex-col">
        <Graph />
        <PaymentMethods />
      </div>

      <InvoiceTable />
    </section>
  );
};

export default Dashboard;
