import React from "react";
import SearchBar from "../components/ui/SearchBar";
import { Navigate, Outlet } from "react-router";
import SideMenu from "../components/ui/SideMenu";
import Footer from "../components/ui/Footer";

const RootPage = () => {
  const token = localStorage.getItem("token");
  return (
    <div className="bg-bodyBG flex">
      <div>
        <SideMenu />
      </div>
      <div className="flex-1 min-w-0">
        <div className="sm:px-[26px]">
          <SearchBar />
        </div>
        <div className=" px-[26px]">
          {token ? <Outlet /> : <Navigate to="login" />}
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default RootPage;
