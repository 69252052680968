import React, { useEffect, useState } from "react";
import ProductsTable from "./ProductsTable";
import Modal from "../ui/Modal";
import AddProductModal from "./AddProductModal";
import cart from "../../assets/icons/cart.svg";
import StatCard from "../ui/StatCard";
import { getProductsNumber } from "../../api/AppManagement";
import Loader from "../ui/Loader";

const Products = () => {
  const [loading, setLoading] = useState(false);
  const [totalProducts, setTotalProducts] = useState();

  const [isModelOpen, setIsModelOpen] = useState(false);

  const [newProduct, setNewProduct] = useState();

  const openModal = () => {
    setIsModelOpen(true);
  };

  const closeModal = () => {
    setIsModelOpen(false);
  };

  useEffect(() => {
    const cardTotalProduct = async () => {
      setLoading(true);
      const { data } = await getProductsNumber();
      setTotalProducts(data);
      setLoading(false);
    };
    cardTotalProduct();
  }, []);

  return (
    <section
      className={`flex flex-col gap-[26px] pt-[26px] ${
        loading && "h-[calc(100vh-62px)]"
      }`}
    >
      <h3 className="page-heading">Products</h3>
      {!loading && totalProducts && (
        <>
          <StatCard
            title="Total Products"
            number={totalProducts.total}
            icon={cart}
          />
          <ProductsTable openModal={openModal} newProduct={newProduct} />
        </>
      )}
      {loading && <Loader />}
      <Modal isOpen={isModelOpen} onClose={closeModal} title="Add Product">
        <AddProductModal
          closeModal={closeModal}
          setNewProduct={setNewProduct}
        />
      </Modal>
    </section>
  );
};

export default Products;
