import React from 'react'
import Reports from '../components/reports/Reports'

const ReportsPage = () => {
  return (
    <Reports />
  )
}

export default ReportsPage
