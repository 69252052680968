import React, { useState } from 'react';
import {
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from '@nextui-org/react';
import editIcon from '../../assets/icons/editIcon.svg';
import Modal from '../ui/Modal';
import EditNotificationsModal from './EditNotificationsModal'
const Notifications = () => {
  const [isModelOpen, setIsModelOpen] = useState(false);

  const openModal = () => {
    setIsModelOpen(true);
  };

  const closeModal = () => {
    setIsModelOpen(false);
  };
  return (
    <div className='w-full p-6 flex flex-col gap-6 bg-white rounded-md shadow-md'>
      <div className='flex flex-col items-start gap-[6px]'>
        <h3 className='text-[#4B465C] text-lg capitalize font-semibold'>
          Recent send
        </h3>
        <p className='text-[#4B465C] text-xs leading-[22px] capitalize'>
          Change to notification settings, the user will get the update
        </p>
      </div>

      <Table
        aria-label='table'
        classNames={{
          wrapper: 'rounded-md px-0 py-0',
          th: 'uppercase bg-transparent px-[20px] py-[16.5px] border-b',
          td: 'py-[11px] px-[20px]',
        }}
      >
        <TableHeader>
          <TableColumn className='text-[#4B465C] text-[13px] font-semibold tracking-[1px] capitalize'>
            Notifications
          </TableColumn>
          <TableColumn className='text-[#4B465C] text-[13px] font-semibold tracking-[1px] capitalize'>
            EMAIL
          </TableColumn>
          <TableColumn className='text-[#4B465C] text-[13px] font-semibold tracking-[1px] capitalize'>
            APP
          </TableColumn>
        </TableHeader>

        <TableBody>
          <TableRow key='1'>
            <TableCell>
              <div className='flex justify-between items-center'>
                <p className='text-[#4B465C] text-xs font-bold leading-[22px] capitalize'>
                  New For You
                </p>
                <p className='text-[#4B465C] text-xs font-normal leading-[22px] capitalize'>
                  Asdfjkdlsf
                </p>
                <img src={editIcon} alt='edit icon' />
              </div>
            </TableCell>
            <TableCell>
              <Checkbox
                defaultSelected
                radius='sm'
                color='default'
                classNames={{
                  wrapper: ['group-data-[selected=true]:after:bg-primary-500'],
                  icon: ['group-data-[selected=true]:text-white'],
                }}
              />
            </TableCell>
            <TableCell>
              <Checkbox
                defaultSelected radius='sm'
                color='default'
                classNames={{
                  wrapper: ['group-data-[selected=true]:after:bg-primary-500'],
                  icon: ['group-data-[selected=true]:text-white'],
                }}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <Button
        size='sm'
        className='w-max bg-primary-500 text-white text-sm rounded-md shadow-[0px_2px_4px_0px_rgba(165, 163, 174, 0.30)]'
        onClick={openModal}
      >
        Send
      </Button>
      <Modal isOpen={isModelOpen} onClose={closeModal} title="Edit Notifications">
        <EditNotificationsModal closeModal={closeModal} />
      </Modal>
    </div>
  );
};

export default Notifications;
