import HomeIcon from "../components/ui/icons/HomeIcon";
import CartIcon from "../components/ui/icons/CartIcon";
import UsersIcon from "../components/ui/icons/UsersIcon";
import StoreReportsIcon from "../components/ui/icons/StoreReportsIcon";
import ReportsIcon from "../components/ui/icons/ReportsIcon";
import InvoicesIcon from "../components/ui/icons/InvoicesIcon";
import PaymentsIcon from "../components/ui/icons/PaymentsIcon";
import PackagesIcon from "../components/ui/icons/PackagesIcon";
import SettingsIcon from "../components/ui/icons/SettingsIcon";
import StaffIcon from "../components/ui/icons/StaffIcon";
import FolderIcon from "../components/ui/icons/FolderIcon";

export const MANAGE_APP = [
  {
    name: "Store Management",
    url: "/stores",
    icon: <HomeIcon color="#4B465C" />,
    activeIcon: <HomeIcon color="#fff" />,
  },
  {
    name: "Products",
    url: "/products",
    icon: <CartIcon color="#4B465C" />,
    activeIcon: <CartIcon color="#fff" />,
  },
  {
    name: "Expense's Resasons",
    url: "/reasons",
    icon: <FolderIcon color="#4B465C" />,
    activeIcon: <FolderIcon color="#fff" />,
  },
  {
    name: "Roles & Permissions",
    url: "/roles",
    icon: <UsersIcon color="#4B465C" />,
    activeIcon: <UsersIcon color="#fff" />,
  },
  {
    name: "Store Reports",
    url: "/store-reports",
    icon: <StoreReportsIcon color="#4B465C" />,
    activeIcon: <StoreReportsIcon color="#fff" />,
  },
];

export const MANAGE_DASHBOARD = [
  {
    name: "Reports",
    url: "/reports",
    icon: <ReportsIcon color="#4B465C" />,
    activeIcon: <ReportsIcon color="#fff" />,
  },
  {
    name: "Invoices",
    url: "/invoices",
    icon: <InvoicesIcon color="#4B465C" />,
    activeIcon: <InvoicesIcon color="#fff" />,
  },
  {
    name: "Manage Payments",
    url: "/manage-payments",
    icon: <PaymentsIcon color="#4B465C" />,
    activeIcon: <PaymentsIcon color="#fff" />,
  },
  {
    name: "Manage Packages",
    url: "/manage-packages",
    icon: <PackagesIcon color="#4B465C" />,
    activeIcon: <PackagesIcon color="#fff" />,
  },
  {
    name: "Roles",
    url: "/admin-roles",
    icon: <SettingsIcon color="#4B465C" />,
    activeIcon: <SettingsIcon color="#fff" />,
  },
  {
    name: "Staff",
    url: "/staff",
    icon: <StaffIcon color="#4B465C" />,
    activeIcon: <StaffIcon color="#fff" />,
  },
];
