import React from "react";
import Stores from "../components/stores/Stores";

const StoresPage = () => {
  return (
      <Stores />
  );
};

export default StoresPage;
