import React from "react";
import { Switch } from "@nextui-org/switch";
import { Button } from "@nextui-org/button";
import Input from "../ui/Input";
import SelectComponent from "../ui/SelectComponent";
import percentage from "../../assets/icons/percentage.svg";
import dollar from "../../assets/icons/dollar.svg";

const ConfirmPaymentsModal = ({ closeModal }) => {
  return (
    <div className="flex flex-col gap-4">
      <p className="text-black text-lg leading-[33px] font-normal capitalize">
        1,APRIL,2023 - 30,APRIL,2023
      </p>
      <Switch
        classNames={{
          wrapper: "group-data-[selected=true]:bg-primary-500",
        }}
      >
        PAID
      </Switch>

      <div className="flex flex-col items-start w-full gap-1 ">
        <label className="text-[13px] capitalize font-normal text-[#4B465C]">
          SELECT PAYMENT METHOD
        </label>
        <SelectComponent options={[]} placeholder="CASH" className="w-full" />
      </div>

      <div className="flex items-start w-full gap-4 ">
        <Input
          title="DISCOUNT"
          type="number"
          name="discount"
          placeholder="0"
          icon={percentage}
        />
        <Input
          title="Discount reason"
          type="text"
          name="discountReason"
          placeholder=""
        />
      </div>

      <Input
        title="TOTAL INCOME"
        type="number"
        name="totalIncome"
        placeholder="0"
        icon={dollar}
      />

      <div className="flex items-start gap-4 mt-[10px]">
        <Button
          size="sm"
          className="w-max bg-primary-500 text-white text-sm rounded-md py-[10px] px-[20px] shadow-[0px_2px_4px_0px_rgba(165, 163, 174, 0.30)] capitalize"
        >
          CONFIRM
        </Button>

        <Button
          size="sm"
          className="w-max bg-secondary-200 text-secondary-500 text-sm rounded-md py-[10px] px-[20px] shadow-[0px_2px_4px_0px_rgba(165, 163, 174, 0.30)] capitalize"
          onClick={closeModal}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default ConfirmPaymentsModal;
