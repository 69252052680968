import { RouterProvider, createBrowserRouter } from "react-router-dom";
import RootPage from "./pages/RootPage";
import DashboardPage from "./pages/DashboardPage";
import StoresPage from "./pages/StoresPage";
import SingleStorePage from "./pages/SingleStorePage";
import Account from "./components/single-store/account/Account";
import ProductsPage from "./pages/ProductsPage";
import Billing from "./components/single-store/Billing";
import Notifications from "./components/single-store/Notifications";
import Plan from "./components/single-store/Plan";
import RolesPage from "./pages/RolesPage";
import StoreReportsPage from "./pages/StoreReportsPage";
import ReportsPage from "./pages/ReportsPage";
import InvoicePage from "./pages/InvoicePage";
import PaymentsPage from "./pages/PaymentsPage";
import PackagesPage from "./pages/PackagesPage";
import AdminRolePage from "./pages/AdminRolePage";
import StaffPage from "./pages/StaffPage";
import StaffMemberPage from "./pages/StaffMemberPage";
import LoginPage from "./pages/LoginPage";
import PreviewInvoicePage from "./pages/PreviewInvoicePage";
import AddInvoicePage from "./pages/AddInvoicePage";
import ReasonsPage from "./pages/ReasonsPage";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <RootPage />,
      children: [
        { path: "", element: <DashboardPage /> },
        { path: "stores", element: <StoresPage /> },
        {
          path: "stores/store/:id",
          element: <SingleStorePage />,
          children: [
            { path: "account", element: <Account /> },
            { path: "billing", element: <Billing /> },
            { path: "notifications", element: <Notifications /> },
            { path: "plan", element: <Plan /> },
          ],
        },
        { path: "products", element: <ProductsPage /> },
        { path: "reasons", element: <ReasonsPage /> },
        { path: "roles", element: <RolesPage /> },
        { path: "store-reports", element: <StoreReportsPage /> },
        { path: "reports", element: <ReportsPage /> },
        { path: "invoices", element: <InvoicePage /> },
        { path: "invoices/:id", element: <PreviewInvoicePage /> },
        { path: "invoices/add-invoice", element: <AddInvoicePage /> },
        { path: "manage-payments", element: <PaymentsPage /> },
        { path: "manage-packages", element: <PackagesPage /> },
        { path: "admin-roles", element: <AdminRolePage /> },
        { path: "staff", element: <StaffPage /> },
        { path: "staff/:id", element: <StaffMemberPage /> },
      ],
    },
    { path: "/login", element: <LoginPage /> },
  ]);
  return <RouterProvider router={router} />;
}

export default App;
