import React from 'react'
import Reasons from '../components/reasons/Reasons'

const ReasonsPage = () => {
  return (
    <Reasons />
  )
}

export default ReasonsPage
