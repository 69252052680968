import { Button } from "@nextui-org/react";
import React from "react";
import calender from "../../assets/icons/calender.svg";

const Card = ({ icon, title, subtitle, onOpen }) => {
  return (
    <div className="w-full flex justify-center items-center gap-2 bg-white rounded-md py-6 px-4 shadow-gray-card border border-primary-500 max-lg:flex-col">
      <img src={icon} alt="sales" className="w-[64px] h-[64px]" />
      <div className="w-full flex flex-col justify-center items-start gap-2 max-lg:text-center max-lg:items-center">
        <h3 className="text-[22px] leading-[30px] font-semibold text-[#4B465C] capitalize">
          {title}
        </h3>
        <p className="text-[#4B465C] text-[13px] leading-5 font-normal capitalize">
          {subtitle}
        </p>
      </div>
      <div>
        <Button
          radius="sm"
          className="bg-primary-500 py-[21px] px-[26px] flex justify-center items-center gap-[6px] border-r border-primary-600 text-[#4B4B4B] text-[17px] leading-[22px] font-medium tracking-[0.43px] capitalize shadow-gray-sm"
          onClick={onOpen}
        >
          Export
          <span className="w-[24px] h-[24px]">
            <img src={calender} alt="calender" />
          </span>
        </Button>
      </div>
    </div>
  );
};

export default Card;
