import {
  Dropdown,
  DropdownMenu,
  DropdownTrigger
} from "@nextui-org/react";
import React from "react";
import dots from "../../assets/icons/dots-vertical.svg";

const DropdownMenus = ({ onDelete, openEditModal, children }) => {
  return (
    <Dropdown placement="bottom-start" aria-label="dropdown">
      <DropdownTrigger>
        <img src={dots} alt="dots" className="cursor-pointer" />
      </DropdownTrigger>
      <DropdownMenu variant="flat" aria-label="dropdown menu">{children}</DropdownMenu>
    </Dropdown>
  );
};

export default DropdownMenus;
