import { Chip } from "@nextui-org/chip";
import {
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "@nextui-org/table";
import React, { useMemo, useState } from "react";
import { deleteSubUser } from "../../../api/AppManagement";
import edit from "../../../assets/icons/edit.svg";
import DeleteIcon from "../../../assets/icons/trash.svg";
import EditSubUsersModal from "./EditSubUsersModal";
import Modal from "../../ui/Modal";

const columnsHeaders = [
  { id: "users", name: "Users", sortable: true },
  { id: "role", name: "Role", sortable: true },
  { id: "status", name: "Status", sortable: true },
  { id: "actions", name: "Actions", sortable: true },
];

const AccountTable = ({
  subUsers,
  onDeleteSubUser,
  onUpdateSubUser,
  storeId,
  update,
  setUpdate,
}) => {
  const [sortDescriptor, setSortDescriptor] = useState({
    column: "store",
    direction: "ascending",
  });

  const sortedItems = useMemo(() => {
    return [...subUsers].sort((a, b) => {
      const first = a[sortDescriptor.column];
      const second = b[sortDescriptor.column];
      const cmp = first < second ? -1 : first > second ? 1 : 0;
      return sortDescriptor.direction === "descending" ? -cmp : cmp;
    });
  }, [sortDescriptor.column, sortDescriptor.direction, subUsers]);

  const deleteSubUserHandler = async (id) => {
    await deleteSubUser(id);
    onDeleteSubUser(id);
  };

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedStore, setSelectedStore] = useState(null);

  const openEditModal = (store) => {
    setSelectedStore(store);
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setSelectedStore(null);
    setUpdate(true);
  };

  return (
    <>
      <Table
        aria-label="stores_table"
        sortDescriptor={sortDescriptor}
        onSortChange={setSortDescriptor}
        classNames={{
          wrapper: "rounded-[0] px-0 py-0",
          th: " uppercase bg-transparent px-[20px] py-2 border-b",
          td: " py-5 px-[20px]",
        }}
      >
        <TableHeader columns={columnsHeaders}>
          {(column) => (
            <TableColumn
              key={column.uid}
              allowsSorting={column.sortable}
              align={column.uid === "actions" ? "center" : "start"}
            >
              {column.name}
            </TableColumn>
          )}
        </TableHeader>
        <TableBody items={sortedItems} emptyContent={"No stores found"}>
          {(item) => (
            <TableRow key={item.id} className="border-b">
              <TableCell>
                <div className="flex items-center gap-[10px]">
                  {/* <img
                    src={item.user.image}
                    alt={item.user.name}
                    className="w-[40px] h-[40px] rounded-full"
                  /> */}
                  <div>
                    <p className="text-sm font-semibold">
                      {item.attributes.name}
                    </p>
                    {/* <p className="text-xs text-secondary-500">{item.user.date}</p> */}
                  </div>
                </div>
              </TableCell>
              <TableCell>
                <div className="flex gap-2">
                  {/* {item.roles.map((role, index) => (
                    <Chip
                      key={index}
                      classNames={{
                        base: [
                          `${index === 0 ? "bg-primary-100" : "bg-success-100"
                          } rounded-md`,
                        ],
                        content: [
                          `${index === 0 ? "text-primary-700" : "text-success-700"
                          }`,
                        ],
                      }}
                    >
                      {role}
                    </Chip>
                  ))} */}

                  <Chip
                    classNames={{
                      base: "bg-success-100 rounded-md",
                      content: "text-success-700",
                    }}
                  >
                    {item.attributes.role}
                  </Chip>
                </div>
              </TableCell>
              <TableCell className="w-[240px]">
                <Chip
                  radius="sm"
                  className={`p-[5px 10px] text-sm font-semibold capitalize ${
                    item.attributes.status === 1
                      ? "bg-success-200 text-success-500"
                      : "bg-secondary-200 text-secondary-500"
                  }`}
                >
                  {item.attributes.status === 1 ? "Active" : "Inactive"}
                </Chip>
              </TableCell>
              <TableCell>
                <div className="relative flex items-center gap-2">
                  <img
                    className="text-lg cursor-pointer text-danger active:opacity-50"
                    src={DeleteIcon}
                    alt="delete"
                    onClick={() => deleteSubUserHandler(item.id)}
                  />
                  <img
                    src={edit}
                    alt="edit"
                    className="cursor-pointer"
                    onClick={() => openEditModal(item)}
                  />
                </div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Modal
        isOpen={isEditModalOpen}
        onClose={closeEditModal}
        title="Edit Sub User"
      >
        <EditSubUsersModal
          closeModal={closeEditModal}
          subUserData={selectedStore}
          // onUpdateStore={onUpdateStore}
          storeId={storeId}
        />
      </Modal>
    </>
  );
};

export default AccountTable;
