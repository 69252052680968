import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Divider, Select, SelectItem } from "@nextui-org/react";
import ReasonsSingleTable from "./ReasonsSingleTable";
import CustomPagination from "../ui/CustomPagination";
import {
  getFilteredReasons,
  getReasons,
  getStores,
  searchReasons,
} from "../../api/AppManagement";
import Loader from "../ui/Loader";
import plus from "../../assets/icons/plus.svg";
import search from "../../assets/icons/search.svg";

const itemsPerPage = 10;

const ReasonsTable = ({ openModal, newReason }) => {
  const [status, setStatus] = useState("");
  const [stores, setStores] = useState([]);
  const [loadingStores, setLoadingStores] = useState(false);
  const [selectedStore, setSelectedStore] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [loading, setLoading] = useState(false);
  const [reasons, setReasons] = useState();
  const [searchParams] = useSearchParams();
  let page = searchParams.get("page") || 1;
  const [searchTerm, setSearchTerm] = useState("");
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    const getStoresData = async () => {
      setLoadingStores(true);
      const { data } = await getStores();
      console.log(data);
      setStores(data.data);
      setLoadingStores(false);
    };
    getStoresData();
  }, []);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      setSearchTerm(searchInput);
    }, 500);
    return () => clearTimeout(timeOut);
  }, [searchInput]);

  useEffect(() => {
    setLoading(true);
    const getSearchReasonsData = async () => {
      const { data } = await searchReasons(searchTerm);
      console.log("search", data);
      setReasons(data);
      setLoading(false);
    };
    if (searchTerm.trim().length > 0) {
      getSearchReasonsData();
    }
  }, [searchTerm]);

  useEffect(() => {
    const getReasonsData = async () => {
      setLoading(true);
      const { data } = await getReasons();
      console.log(data);
      setReasons(data);
      setLoading(false);
    };
    if (
      searchTerm.trim().length === 0 &&
      selectedStore.trim().length === 0 &&
      selectedType.trim().length === 0 &&
      status.trim().length === 0
    ) {
      getReasonsData();
    }
  }, [searchTerm, selectedStore, selectedType, status]);

  useEffect(() => {
    if (newReason) {
      if (reasons.data.length < 10) {
        setReasons((prev) => {
          return {
            ...prev,
            data: [newReason, ...prev.data],
            // meta: { ...prev.meta, total: prev.meta.total + 1 },
          };
        });
      }
    }
  }, [newReason]);

  const deleteReason = (id) => {
    setReasons((prev) => {
      const updatedReason = reasons.data.filter((store) => store.id !== id);
      return { ...prev, data: updatedReason };
    });
  };

  const updateReason = (newData) => {
    const updatedReasonIndex = reasons.data.findIndex(
      (reason) => parseInt(reason.id) === parseInt(newData.id)
    );
    const updatedReasons = reasons.data;
    updatedReasons[updatedReasonIndex] = newData;
    setReasons((prev) => {
      return { ...prev, data: [...updatedReasons] };
    });
  };

  const filterReasons = async (name, value) => {
    if (value.trim().length > 0) {
      let filter = {};
      filter[name] = value;
      setLoading(true);
      const { data } = await getFilteredReasons(filter, page);
      console.log("filter", name, data);
      setReasons(data);
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col gap-4 py-6 bg-white rounded-md shadow-gray-card">
      <div className="flex flex-col gap-4 px-6">
        <h3 className="text-[18px] leading-[24px] font-semibold text-[#38334F]">
          Search filter
        </h3>
        <div className="flex flex-col gap-6 mb-4 lg:flex-row">
          <div className="flex-1 flex items-center border border-[#DBDADE] py-[7px] px-[14px] rounded-md">
            <input
              placeholder="Search"
              className="focus:outline-none flex-1 text-[15px]"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
            <img src={search} alt="search" className="w-[20px] h-[20px]" />
          </div>
          <div className="flex-1">
            <Select
              variant="bordered"
              size="small"
              placeholder="Select Store"
              aria-label="Select Store"
              isLoading={loadingStores}
              classNames={{
                trigger: [
                  "rounded-md border border-[#DBDADE] py-[7px] px-[14px]",
                  "data-[hover=true]:border-[#DBDADE]",
                  "data-[focus=true]:border-[#DBDADE]",
                  "data-[open=true]:border-[#DBDADE]",
                ],
              }}
              onChange={(e) => {
                console.log(e.target.value);
                setSelectedStore(e.target.value);
                filterReasons("vendor_id", e.target.value);
              }}
              items={stores}
            >
              {(item) => (
                <SelectItem key={item.id} value={item.id}>
                  {item.attributes.name}
                </SelectItem>
              )}
            </Select>
          </div>
          <div className="flex-1">
            <Select
              variant="bordered"
              size="small"
              placeholder="Reason Type"
              aria-label="Reason Type Select"
              classNames={{
                trigger: [
                  "rounded-md border border-[#DBDADE] py-[7px] px-[14px]",
                  "data-[hover=true]:border-[#DBDADE]",
                  "data-[focus=true]:border-[#DBDADE]",
                  "data-[open=true]:border-[#DBDADE]",
                ],
              }}
              onChange={(e) => {
                console.log(e.target.value);
                setSelectedType(e.target.value);
                filterReasons("type", e.target.value);
              }}
            >
              <SelectItem key="expenses" value="expenses">
                Expenses
              </SelectItem>
              <SelectItem key="indebtedness" value="indebtedness">
                Indebtedness
              </SelectItem>
            </Select>
          </div>
          <div className="flex-1">
            <Select
              variant="bordered"
              size="small"
              placeholder="Status"
              aria-label="status"
              classNames={{
                trigger: [
                  "rounded-md border border-[#DBDADE] py-[7px] px-[14px]",
                  "data-[hover=true]:border-[#DBDADE]",
                  "data-[focus=true]:border-[#DBDADE]",
                  "data-[open=true]:border-[#DBDADE]",
                ],
              }}
              onChange={(e) => {
                console.log(e.target.value);
                setStatus(e.target.value);
                filterReasons("status", e.target.value);
              }}
            >
              <SelectItem key="1" value="1">
                Active
              </SelectItem>
              <SelectItem key="0" value="0">
                Inactive
              </SelectItem>
            </Select>
          </div>
        </div>
      </div>
      <Divider />
      <div className="flex flex-col gap-4 px-6 sm:flex-row">
        <button
          className="flex gap-2 px-[20px] py-[10px] items-center max-sm:justify-center rounded-md shadow-gray-sm bg-primary-500 text-white"
          onClick={openModal}
        >
          <img src={plus} alt="add" />
          <p>Add New Reason</p>
        </button>
      </div>
      {!loading && reasons && (
        <ReasonsSingleTable
          data={reasons.data}
          onDeleteReason={deleteReason}
          onUpdateReason={updateReason}
        />
      )}
      {loading && <Loader />}
      {!loading && reasons?.meta !== undefined && (
        <div className="flex flex-col items-center justify-between gap-4 px-6 lg:flex-row">
          <p className="w-full flex justify-center lg:justify-start text-[13px] text-[#B8B0CE]">
            Showing {(page - 1) * itemsPerPage + 1} to{" "}
            {(page - 1) * itemsPerPage + reasons.data.length} of{" "}
            {reasons.meta.total} entries
          </p>
          <div className="flex justify-center w-full lg:justify-end">
            <CustomPagination
              page={page}
              count={Math.ceil(reasons.meta.total / itemsPerPage)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ReasonsTable;
