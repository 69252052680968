import React, { useEffect, useState } from "react";
import Input from "../ui/Input";
import { Button, Select, SelectItem } from "@nextui-org/react";
import { addReason, getStores } from "../../api/AppManagement";

const ModalReasonsContent = ({ closeModal, setNewReason }) => {
  const [vendorId, setVendorId] = useState();
  const [name, setName] = useState();
  const [type, setType] = useState();
  const [stores, setStores] = useState();

  const addReasonHandler = async () => {
    const { data } = await addReason({
      vendor_id: parseInt(vendorId),
      name: name,
      type: type,
    });
    console.log(data);
    setNewReason(data.data);
    closeModal();
  };

  const selectTypeHandler = (e) => {
    setType(e.target.value);
  };
  useEffect(() => {
    const getStoresData = async () => {
      const { data } = await getStores();
      console.log(data);
      setStores(data.data);
    };
    getStoresData();
  }, []);
  return (
    <div className="flex flex-col gap-4">
      <Input
        title="Reason Name"
        type="text"
        name="name"
        placeholder="فاتورة ايجار"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />

      <div className="flex flex-col items-start gap-1 w-full">
        <label className="text-[13px] capitalize font-normal text-[#4B465C]">
          Select Store
        </label>
        <Select
          variant="bordered"
          size="small"
          placeholder="Select Store"
          aria-label="Select Store"
          classNames={{
            trigger: [
              "rounded-md border border-[#DBDADE] py-[7px] px-[14px]",
              "data-[hover=true]:border-[#DBDADE]",
              "data-[focus=true]:border-[#DBDADE]",
              "data-[open=true]:border-[#DBDADE]",
            ],
          }}
          onChange={(e) => {
            console.log(e.target.value);
            setVendorId(e.target.value);
          }}
          items={stores ? stores : []}
        >
          {(item) => (
            <SelectItem key={item.id} value={item.id}>
              {item.attributes.name}
            </SelectItem>
          )}
        </Select>
      </div>

      <div className="flex flex-col items-start gap-1 w-full">
        <label className="text-[13px] capitalize font-normal text-[#4B465C]">
          Select Type
        </label>
        <Select
          variant="bordered"
          size="small"
          placeholder="Reason Type"
          aria-label="Reason Type Select"
          classNames={{
            trigger: [
              "rounded-md border border-[#DBDADE] py-[7px] px-[14px]",
              "data-[hover=true]:border-[#DBDADE]",
              "data-[focus=true]:border-[#DBDADE]",
              "data-[open=true]:border-[#DBDADE]",
            ],
          }}
          onChange={(e) => {
            console.log(e.target.value);
            setType(e.target.value);
          }}
        >
          <SelectItem key="expenses" value="expenses">
            Expenses
          </SelectItem>
          <SelectItem key="indebtedness" value="indebtedness">
            Indebtedness
          </SelectItem>
        </Select>
      </div>

      <div className="flex items-start gap-4 mt-[10px]">
        <Button
          size="sm"
          className="w-max bg-primary-500 text-white text-[15px] font-medium tracking-[0.43px] rounded-md py-[10px] px-[20px] shadow-gray-sm capitalize"
          onClick={addReasonHandler}
        >
          Add
        </Button>

        <Button
          size="sm"
          className="w-max bg-secondary-200 text-secondary-500 text-[15px] font-medium tracking-[0.43px] rounded-md py-[10px] px-[20px] shadow-gray-sm capitalize"
          onClick={closeModal}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default ModalReasonsContent;
