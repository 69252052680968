import { Button, Select, SelectItem } from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import CustomPagination from "../ui/CustomPagination";
import SelectComponent from "../ui/SelectComponent";
import PlusBtn from "../ui/icons/PlusBtn";
import InvoiceSingleTable from "./InvoiceSingleTable";
import {
  getFilteredInvoice,
  getInvoice,
  searchInvoice,
} from "../../api/DashboardManagement";
import Loader from "../ui/Loader";

const InvoiceTable = ({ newInvoice }) => {
  const navigate = useNavigate();

  const [selectedStatus, setSelectedStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [invoices, setInvoices] = useState();

  const [searchTerm, setSearchTerm] = useState("");
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    const getInvoiceData = async () => {
      setLoading(true);
      const { data } = await getInvoice();
      setInvoices(data);
      setLoading(false);
    };
    if (searchTerm.trim().length === 0 && selectedStatus.trim().length === 0) {
      getInvoiceData();
    }
  }, [searchTerm, selectedStatus]);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      setSearchTerm(searchInput);
    }, 500);
    return () => clearTimeout(timeOut);
  }, [searchInput]);

  useEffect(() => {
    setLoading(true);
    const getSearchInvoicesData = async () => {
      const { data } = await searchInvoice(searchTerm);
      console.log("search", data);
      setInvoices(data);
      setLoading(false);
    };
    if (searchTerm.trim().length > 0) {
      getSearchInvoicesData();
    }
  }, [searchTerm]);

  useEffect(() => {
    if (newInvoice) {
      if (invoices.data.length < 10) {
        setInvoices((prev) => {
          return {
            ...prev,
            data: [newInvoice, ...prev.data],
            meta: { ...prev.meta, total: prev.meta.total + 1 },
          };
        });
      }
    }
  }, [newInvoice]);

  const deleteInvoice = (id) => {
    setInvoices((prev) => {
      const updatedInvoice = invoices.data.filter(
        (invoice) => invoice.id !== id
      );
      return { ...prev, data: updatedInvoice };
    });
  };

  const updateInvoice = (newData) => {
    const updatedInvoiceIndex = invoices.data.findIndex(
      (invoice) => parseInt(invoice.id) === parseInt(newData.id)
    );
    const updatedInvoices = invoices.data;
    updatedInvoices[updatedInvoiceIndex] = newData;
    setInvoices((prev) => {
      return { ...prev, data: [...updatedInvoices] };
    });
  };

  const filterProducts = async (name, value) => {
    if (value.trim().length > 0) {
      let filter = {};
      filter[name] = value;
      setLoading(true);
      const { data } = await getFilteredInvoice(filter);
      setInvoices(data);
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col gap-4 py-6 bg-white rounded-md shadow-gray-card">
      <div className="flex items-center justify-between w-full px-6 py-4 max-lg:flex-col max-lg:gap-4">
        <div className="max-lg:w-full flex max-lg:flex-col gap-[10px] items-center justify-center">
          <Button
            startContent={<PlusBtn />}
            className="max-lg:w-full bg-primary-500 text-white text-[13px] py-[6px] px-[14px] rounded-[4px] shadow-gray-card"
            onClick={() => {
              navigate("/invoices/add-invoice");
            }}
          >
            Create Invoice
          </Button>
        </div>
        <div className="flex gap-4 max-lg:w-full max-lg:flex-col">
          <input
            placeholder="Search"
            className="focus:outline-none flex-1 text-[15px] border border-[#DBDADE] py-[7px] px-[14px] rounded-md"
            onChange={(e) => {
              setSearchInput(e.target.value);
            }}
            value={searchInput}
          />
          <div className="flex-1">
            <Select
              variant="bordered"
              size="small"
              placeholder="Product Status"
              aria-label="Product Status"
              classNames={{
                trigger:
                  "dropdown data-[hover=true]:border-[#DBDADE] data-[focus=true]:border-[#DBDADE] data-[open=true]:border-[#DBDADE]",
              }}
              onChange={(e) => {
                setSelectedStatus(e.target.value);
                filterProducts("status", e.target.value);
              }}
            >
              <SelectItem key="1" value="1">
                Active
              </SelectItem>
              <SelectItem key="0" value="0">
                Inactive
              </SelectItem>
            </Select>
          </div>
        </div>
      </div>
      {loading && <Loader />}
      {!loading && invoices && (
        <InvoiceSingleTable
          data={invoices.data}
          onDeleteInvoice={deleteInvoice}
          onUpdateInvoice={updateInvoice}
        />
      )}
      <div className="flex flex-col items-center justify-between gap-4 px-6 lg:flex-row">
        <p className="w-full flex justify-center lg:justify-start text-[13px] text-[#B8B0CE]">
          Showing 1 to 10 of 100 entries
        </p>
        <div className="flex justify-center w-full lg:justify-end">
          <CustomPagination />
        </div>
      </div>
    </div>
  );
};

export default InvoiceTable;
