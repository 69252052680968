import React, { useState } from "react";
import { Button } from "@nextui-org/react";
import Input from "../ui/Input";
import { addPayment } from "../../api/DashboardManagement";

const PaymentModal = ({ closeModal, setNewPayment }) => {
  const [name, setName] = useState("");

  const addPaymentHandler = async () => {
    const { data } = await addPayment(name);
    console.log(data);
    setNewPayment(data.data);
    closeModal();
  };

  return (
    <div className="flex flex-col gap-4">
      <Input
        title="Payment Name"
        type="text"
        name="payment"
        placeholder="Vodafone Cash"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <div className="flex items-start gap-4 mt-[10px]">
        <Button
          size="sm"
          className="w-max bg-primary-500 text-white text-sm rounded-md py-[10px] px-[20px] shadow-[0px_2px_4px_0px_rgba(165, 163, 174, 0.30)] capitalize"
          onClick={addPaymentHandler}
        >
          Add
        </Button>

        <Button
          size="sm"
          className="w-max bg-secondary-200 text-secondary-500 text-sm rounded-md py-[10px] px-[20px] shadow-[0px_2px_4px_0px_rgba(165, 163, 174, 0.30)] capitalize"
          onClick={closeModal}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default PaymentModal;
