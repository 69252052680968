import {
  Chip,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  DropdownItem,
} from "@nextui-org/react";
import React, { useMemo, useState } from "react";
import {
  changeStatusPackage,
  deletePackage,
} from "../../api/DashboardManagement";
import eye from "../../assets/icons/eye.svg";
import edit from "../../assets/icons/edit.svg";
import trash from "../../assets/icons/trash.svg";
import status from '../../assets/icons/toggle-status.svg'
import { formatDate } from "../../helpers/format-date";
import DropdownMenus from "../ui/DropdownMenus";
import Modal from "../ui/Modal";
import EditPackageModal from "./EditPackageModal";

const columnsHeaders = [
  { id: "id", name: "ID", sortable: true },
  { id: "payment_name", name: "Package Name", sortable: true },
  { id: "price", name: "Package Price", sortable: true },
  { id: "date", name: "Created Date", sortable: true },
  { id: "status", name: "Status", sortable: true },
  { id: "actions", name: "Actions", sortable: false },
];

const PackagesSingleTable = ({
  packages,
  onDeletePackage,
  onUpdatePackage,
}) => {
  const [isEditing, setIsEditing] = useState(false);

  const [sortDescriptor, setSortDescriptor] = useState({
    column: "id",
    direction: "ascending",
  });

  const sortedItems = useMemo(() => {
    return [...packages].sort((a, b) => {
      let first;
      let second;
      let cmp;
      switch (sortDescriptor.column) {
        case "id":
          first = a.id;
          second = b.id;
          cmp = first < second ? -1 : first > second ? 1 : 0;
          return sortDescriptor.direction === "descending" ? -cmp : cmp;
        case "payment_name":
          first = a.attributes.name;
          second = b.attributes.name;
          cmp = first < second ? -1 : first > second ? 1 : 0;
          return sortDescriptor.direction === "descending" ? -cmp : cmp;
        case "price":
          first = a.attributes.price;
          second = b.attributes.price;
          cmp = first < second ? -1 : first > second ? 1 : 0;
          return sortDescriptor.direction === "descending" ? -cmp : cmp;
        case "date":
          first = a.attributes["created_at"];
          second = b.attributes["created_at"];
          cmp = first < second ? -1 : first > second ? 1 : 0;
          return sortDescriptor.direction === "descending" ? -cmp : cmp;
        case "status":
          first = a.attributes.status;
          second = b.attributes.status;
          cmp = first < second ? -1 : first > second ? 1 : 0;
          return sortDescriptor.direction === "descending" ? -cmp : cmp;

        default:
          first = a[sortDescriptor.column];
          second = b[sortDescriptor.column];
          cmp = first < second ? -1 : first > second ? 1 : 0;
          return sortDescriptor.direction === "descending" ? -cmp : cmp;
      }
    });
  }, [packages, sortDescriptor]);

  const deleteHandler = async (id) => {
    await deletePackage(id);
    onDeletePackage(id);
  };

  const toggleEditing = () => {
    setIsEditing(!isEditing);
  };

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);

  const openEditModal = (product) => {
    setSelectedPackage(product);
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setSelectedPackage(null);
    toggleEditing();
  };

  const toggleStatus = async (id, currentStatus) => {
    const newStatus = currentStatus == 1 ? 0 : 1;
    const { data } = await changeStatusPackage(id, newStatus);
    onUpdatePackage(data.data);
  };

  return (
    <>
      <Table
        aria-label="stores_table"
        sortDescriptor={sortDescriptor}
        onSortChange={setSortDescriptor}
        classNames={{
          wrapper: "rounded-[0] px-0 py-0",
          th: "uppercase bg-transparent px-6 py-4 border-b",
          td: "py-8 px-6",
        }}
      >
        <TableHeader columns={columnsHeaders}>
          {(column) => (
            <TableColumn key={column.id} allowsSorting={column.sortable}>
              {column.name}
            </TableColumn>
          )}
        </TableHeader>
        <TableBody items={sortedItems} emptyContent={"No stores found"}>
          {(item) => (
            <TableRow key={item.id} className="border-b">
              <TableCell>{item.id}</TableCell>
              <TableCell className="md:w-[300px]">
                {item.attributes.name}
              </TableCell>
              <TableCell>{item.attributes.price}</TableCell>
              <TableCell>{formatDate(item.attributes.created_at)}</TableCell>
              <TableCell>
                <Chip
                  radius="sm"
                  className={`p-[5px 10px] text-sm font-semibold capitalize ${item.attributes.status == 1
                      ? "bg-success-200 text-success-500"
                      : "bg-secondary-200 text-secondary-500"
                    }`}
                >
                  {item.attributes.status == 1 ? "Active" : "Inactive"}
                </Chip>
              </TableCell>
              <TableCell>
                <div className="flex gap-2 items-center w-fit">
                  <img
                    src={eye}
                    alt="eye"
                    className="cursor-pointer"
                    onClick={() => {
                      openEditModal(item);
                      setIsEditing(false);
                    }}
                  />
                  <DropdownMenus>
                    <DropdownItem
                      key="edit"
                      startContent={<img src={edit} alt="dots" />}
                      onClick={() => {
                        openEditModal(item);
                        setIsEditing(true);
                      }}
                    >
                      Edit
                    </DropdownItem>
                    <DropdownItem
                      key="delete"
                      className="text-danger"
                      startContent={
                        <img src={trash} alt="dots" className="text-danger" />
                      }
                      onClick={() => deleteHandler(item.id)}
                    >
                      Delete
                    </DropdownItem>
                    <DropdownItem
                      key="status"
                      startContent={
                        <img src={status} alt="toggle" />
                      }
                      onClick={() => {
                        toggleStatus(item.id, item.attributes.status);
                        console.log(item.id, item.attributes.status);
                      }}
                    >
                      Status
                    </DropdownItem>
                  </DropdownMenus>
                </div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Modal
        isOpen={isEditModalOpen}
        onClose={closeEditModal}
        title={isEditing ? "Edit Package" : "Preview Package"}
      >
        {selectedPackage && (
          <EditPackageModal
            closeModal={closeEditModal}
            packageData={selectedPackage}
            toggleEditing={toggleEditing}
            isEditing={isEditing}
            onUpdatePackage={onUpdatePackage}
          />
        )}
      </Modal>
    </>
  );
};

export default PackagesSingleTable;
