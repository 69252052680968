export const egypt_cities =
    [
        {
            "name": "Cairo"
        },
        {
            "name": "Alexandria"
        },
        {
            "name": "Giza"
        },
        {
            "name": "Shubra El Kheima"
        },
        {
            "name": "Port Said"
        },
        {
            "name": "Suez"
        },
        {
            "name": "Luxor"
        },
        {
            "name": "al-Mansura"
        },
        {
            "name": "El-Mahalla El-Kubra"
        },
        {
            "name": "Tanta"
        },
        {
            "name": "Asyut"
        },
        {
            "name": "Ismailia"
        },
        {
            "name": "Fayyum"
        },
        {
            "name": "Zagazig"
        },
        {
            "name": "Aswan"
        },
        {
            "name": "Damietta"
        },
        {
            "name": "Damanhur"
        },
        {
            "name": "El-Minya"
        },
        {
            "name": "Beni Suef"
        },
        {
            "name": "Qena"
        },
        {
            "name": "Sohag"
        },
        {
            "name": "Hurghada"
        },
        {
            "name": "6th of October City"
        },
        {
            "name": "Sharm El Sheikh"
        },
        {
            "name": "Banha"
        },
        {
            "name": "Kafr El Sheikh"
        },
    ]