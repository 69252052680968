import React from "react";
import { Select, SelectItem } from "@nextui-org/react";

const SelectComponent = ({
  options,
  placeholder,
  className,
  value,
  onClick,
}) => {
  return (
    <Select
      variant="bordered"
      size="small"
      placeholder={placeholder}
      aria-label="status"
      className={className}
      value={value}
      classNames={{
        base:{

        },
        trigger: [
          "rounded-md border border-[#DBDADE] py-[7px] px-[14px]",
          "data-[hover=true]:border-[#DBDADE]",
          "data-[focus=true]:border-[#DBDADE]",
          "data-[open=true]:border-[#DBDADE]",
        ],
      }}
      onChange={(e) => {
        onClick(e.target);
      }}
    >
      {options.map((row) => (
        <SelectItem key={row.id} value={row.id}>
          {row.store}
        </SelectItem>
      ))}
    </Select>
  );
};

export default SelectComponent;
