import React from 'react';

const ReportsIcon = ({ color }) => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='22'
            height='22'
            viewBox='0 0 22 22'
            fill='none'
        >
            <path
                d='M8.24967 4.58334H6.41634C5.40382 4.58334 4.58301 5.40415 4.58301 6.41668V17.4167C4.58301 18.4292 5.40382 19.25 6.41634 19.25H15.583C16.5955 19.25 17.4163 18.4292 17.4163 17.4167V6.41668C17.4163 5.40415 16.5955 4.58334 15.583 4.58334H13.7497'
                stroke={color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M8.24967 4.58334H6.41634C5.40382 4.58334 4.58301 5.40415 4.58301 6.41668V17.4167C4.58301 18.4292 5.40382 19.25 6.41634 19.25H15.583C16.5955 19.25 17.4163 18.4292 17.4163 17.4167V6.41668C17.4163 5.40415 16.5955 4.58334 15.583 4.58334H13.7497'
                stroke='white'
                strokeOpacity='0.2'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <rect
                x='8.25'
                y='2.75'
                width='5.5'
                height='3.66667'
                rx='1.83333'
                stroke={color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <rect
                x='8.25'
                y='2.75'
                width='5.5'
                height='3.66667'
                rx='1.83333'
                stroke='white'
                strokeOpacity='0.2'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M12.8337 10.0833H10.542C9.7826 10.0833 9.16699 10.699 9.16699 11.4583C9.16699 12.2177 9.7826 12.8333 10.542 12.8333H11.4587C12.218 12.8333 12.8337 13.449 12.8337 14.2083C12.8337 14.9677 12.218 15.5833 11.4587 15.5833H9.16699'
                stroke={color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M12.8337 10.0833H10.542C9.7826 10.0833 9.16699 10.699 9.16699 11.4583C9.16699 12.2177 9.7826 12.8333 10.542 12.8333H11.4587C12.218 12.8333 12.8337 13.449 12.8337 14.2083C12.8337 14.9677 12.218 15.5833 11.4587 15.5833H9.16699'
                stroke='white'
                strokeOpacity='0.2'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M11.7503 15.5833C11.7503 15.1691 11.4145 14.8333 11.0003 14.8333C10.5861 14.8333 10.2503 15.1691 10.2503 15.5833H11.7503ZM10.2503 16.5C10.2503 16.9142 10.5861 17.25 11.0003 17.25C11.4145 17.25 11.7503 16.9142 11.7503 16.5H10.2503ZM11.7503 9.16666C11.7503 8.75244 11.4145 8.41666 11.0003 8.41666C10.5861 8.41666 10.2503 8.75244 10.2503 9.16666H11.7503ZM10.2503 10.0833C10.2503 10.4975 10.5861 10.8333 11.0003 10.8333C11.4145 10.8333 11.7503 10.4975 11.7503 10.0833H10.2503ZM10.2503 15.5833V16.5H11.7503V15.5833H10.2503ZM10.2503 9.16666V10.0833H11.7503V9.16666H10.2503Z'
                fill={color}
            />
            <path
                d='M11.7503 15.5833C11.7503 15.1691 11.4145 14.8333 11.0003 14.8333C10.5861 14.8333 10.2503 15.1691 10.2503 15.5833H11.7503ZM10.2503 16.5C10.2503 16.9142 10.5861 17.25 11.0003 17.25C11.4145 17.25 11.7503 16.9142 11.7503 16.5H10.2503ZM11.7503 9.16666C11.7503 8.75244 11.4145 8.41666 11.0003 8.41666C10.5861 8.41666 10.2503 8.75244 10.2503 9.16666H11.7503ZM10.2503 10.0833C10.2503 10.4975 10.5861 10.8333 11.0003 10.8333C11.4145 10.8333 11.7503 10.4975 11.7503 10.0833H10.2503ZM10.2503 15.5833V16.5H11.7503V15.5833H10.2503ZM10.2503 9.16666V10.0833H11.7503V9.16666H10.2503Z'
                fill='white'
                fillOpacity='0.2'
            />
        </svg>
    );
};

export default ReportsIcon;
