import React from 'react'
import StaffTable from './StaffTable'

const Staff = () => {
  return (
    <section className="flex flex-col gap-[26px] pt-[26px]">
      <h3 className="page-heading">Staff</h3>
      <StaffTable />
    </section>
  )
}

export default Staff