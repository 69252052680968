import React, { useEffect, useState } from "react";
import salesIcon from "../../assets/icons/sales_stat.svg";
import storesIcon from "../../assets/icons/stores_stat.svg";
import productsIcon from "../../assets/icons/products_stat.svg";
import profitIcon from "../../assets/icons/profit_stat.svg";
import usersIcon from "../../assets/icons/users_stat.svg";
import { getDashboardStatistic } from "../../api/AppManagement";
import Loader from "../ui/Loader";

const Statistics = () => {
  const [statistics, setStatistics] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getStatisticsData = async () => {
      const { data } = await getDashboardStatistic();
      setStatistics(data);
      console.log("data", data);
      setLoading(false);
    };
    getStatisticsData();
  }, []);

  return (
    <div className="flex flex-col lg:flex-row gap-[26px]">
      <div className="flex-1 p-6 rounded-md shadow-gray-card bg-white flex flex-col gap-[34px]">
        <div className="flex justify-between">
          <h3 className="text-[18px] font-semibold leading-6 text-[#4B465C]">
            Dashboard
          </h3>
          <p className="text-[13px] text-[#4b465c8b]">Updated 1 month ago</p>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <div className="flex flex-col md:flex-row max-md:gap-4">
            <div className="flex-1 flex items-center gap-4">
              <img src={salesIcon} alt="sales" />
              <div>
                <p className="text-[18px] font-semibold leading-6 text-[#4B465C]">
                  {statistics.total_billing}
                </p>
                <p className="text-[13px] text-[#4b465cc2]">Sales</p>
              </div>
            </div>
            <div className="flex-1 flex items-center gap-4">
              <img src={storesIcon} alt="sales" />
              <div>
                <p className="text-[18px] font-semibold leading-6 text-[#4B465C]">
                  {statistics.total_store}
                </p>
                <p className="text-[13px] text-[#4b465cc2]">Stores</p>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="lg:min-w-[360px] p-6 rounded-md shadow-gray-card bg-white flex flex-col gap-2 items-center justify-center">
        {loading ? (
          <Loader />
        ) : (
          <>
            <img src={usersIcon} alt="users" />
            <p className="text-[18px] font-semibold leading-6 text-[#4B465C]">
              {statistics.total_staff} / User
            </p>
            <p className="text-[13px] text-[#4b465cc2]">Staff</p>
          </>
        )}
      </div>
    </div>
  );
};

export default Statistics;
