import React, { useEffect, useState } from "react";
import StatCard from "../ui/StatCard";
import total from "../../assets/icons/total-users.svg";
import paid from "../../assets/icons/paid-users.svg";
import unpaid from "../../assets/icons/unpaid-users.svg";
import { getStoresNumber } from "../../api/AppManagement";
const Stats = () => {
  const [statsData, setStatsData] = useState({
    totalStores: 0,
    paidUsers: 0,
    unpaidUsers: 0,
  });

  useEffect(() => {
    const getStats = async () => {
      const { data } = await getStoresNumber();
      console.log(data);
      setStatsData({
        totalStores: data.total_vendor,
        paidUsers: data.total_vendor_paid,
        unpaidUsers: data.total_vendor_unpaid,
      });
    };
    getStats();
  }, []);

  const STATS = [
    {
      title: "Total Stores",
      number: statsData.totalStores,
      icon: total,
    },
    {
      title: "Paid Users",
      number: statsData.paidUsers,
      icon: paid,
    },
    {
      title: "Unpaid Users",
      number: statsData.unpaidUsers,
      icon: unpaid,
    },
  ];
  const content = STATS.map((stat) => (
    <StatCard
      key={stat.title}
      title={stat.title}
      number={stat.number}
      icon={stat.icon}
    />
  ));
  return <div className="flex flex-col lg:flex-row gap-6 ">{content}</div>;
};

export default Stats;
