import React from 'react';

const HomeIcon = ({ color }) => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='22'
            height='22'
            viewBox='0 0 22 22'
            fill='none'
        >
            <path
                d='M4.58333 11H2.75L11 2.75L19.25 11H17.4167'
                stroke={color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M4.58301 11V17.4167C4.58301 18.4292 5.40382 19.25 6.41634 19.25H15.583C16.5955 19.25 17.4163 18.4292 17.4163 17.4167V11'
                stroke={color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M8.25 19.2498V13.7498C8.25 12.7373 9.07081 11.9165 10.0833 11.9165H11.9167C12.9292 11.9165 13.75 12.7373 13.75 13.7498V19.2498'
                stroke={color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export default HomeIcon;
