import { Divider, Select, SelectItem } from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  exportStores,
  getFilteredStores,
  getPlans,
  getStores,
  searchStores,
} from "../../api/AppManagement";
import plus from "../../assets/icons/plus.svg";
import share from "../../assets/icons/screen-share.svg";
import search from "../../assets/icons/search.svg";
import CustomPagination from "../ui/CustomPagination";
import Loader from "../ui/Loader";
import StoresSingleTable from "./StoresSingleTable";

const itemsPerPage = 10;

const StoresTable = ({ openModal, newStore }) => {
  const [stores, setStores] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [paidStatus, setPaidStatus] = useState("");
  const [date, setDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  let page = parseInt(searchParams.get("page")) || 1;
  const [loadingPlans, setLoadingPlans] = useState(false);
  const [selectedPlans, setSelectedPlans] = useState("");
  const [plans, setPlans] = useState();

  const exportStoresPdf = async () => {
    const { data } = await exportStores();
    console.log(data);
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "stores.pdf");
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  useEffect(() => {
    setLoading(true);
    const getStoresData = async () => {
      const { data } = await getStores();
      console.log("all", data);
      setStores(data);
      setLoading(false);
    };
    if (
      searchTerm.trim().length === 0 &&
      selectedPlans.trim().length === 0 &&
      paidStatus.trim().length === 0 &&
      date.trim().length === 0
    ) {
      getStoresData();
    }
  }, [searchTerm, paidStatus, selectedPlans, date]);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      setSearchTerm(searchInput);
    }, 500);
    return () => clearTimeout(timeOut);
  }, [searchInput]);

  useEffect(() => {
    setLoading(true);
    const getSearchStoresData = async () => {
      const { data } = await searchStores(searchTerm);
      console.log("search", data);
      setStores(data);
      setLoading(false);
    };
    if (searchTerm.trim().length > 0) {
      getSearchStoresData();
    }
  }, [searchTerm]);

  useEffect(() => {
    if (newStore) {
      if (stores.data.length < 10) {
        setStores((prev) => {
          return {
            ...prev,
            data: [newStore, ...prev.data],
            meta: { ...prev.meta, total: prev.meta.total + 1 },
          };
        });
      }
    }
  }, [newStore]);

  useEffect(() => {
    const getStoresData = async () => {
      setLoadingPlans(true);
      const { data } = await getPlans();
      console.log(data);
      setPlans(data.data);
      setLoadingPlans(false);
    };
    getStoresData();
  }, []);

  const deleteStore = (id) => {
    setStores((prev) => {
      const updatedStores = prev.data.filter((store) => store.id !== id);
      return {
        ...prev,
        data: updatedStores,
        meta: { ...prev.meta, total: prev.meta.total - 1 },
      };
    });
  };

  const updateStore = (newData) => {
    const updatedStoreIndex = stores.data.findIndex(
      (store) => parseInt(store.id) === parseInt(newData.id)
    );
    const updatedStores = stores.data;
    updatedStores[updatedStoreIndex] = newData;
    setStores((prev) => {
      return { ...prev, data: [...updatedStores] };
    });
  };
  const filterStores = async (name, value) => {
    if (value.trim().length > 0) {
      let filter = {};
      filter[name] = value;
      setLoading(true);
      const { data } = await getFilteredStores(filter, page);
      // console.log('filter', date, data);
      setStores(data);
      setLoading(false);
    }
  };

  return (
    <>
      <div className="flex flex-col gap-4 py-6 rounded-md bg-white shadow-gray-card">
        <div className="flex flex-col gap-4 px-6">
          <h3 className="text-[18px] leading-[24px] font-semibold text-[#38334F]">
            Search filter
          </h3>
          <div className="flex flex-col lg:flex-row gap-6 mb-4">
            <div className="flex-1 flex items-center border border-[#DBDADE] py-[7px] px-[14px] rounded-md">
              <input
                placeholder="Store"
                className="focus:outline-none flex-1 text-[15px]"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
              />
              <img src={search} alt="search" className="w-[20px] h-[20px]" />
            </div>
            <div className="flex-1">
              <Select
                variant="bordered"
                size="small"
                placeholder="Select Plan"
                aria-label="Select Plan"
                isLoading={loadingPlans}
                classNames={{
                  trigger: [
                    "rounded-md border border-[#DBDADE] py-[7px] px-[14px]",
                    "data-[hover=true]:border-[#DBDADE]",
                    "data-[focus=true]:border-[#DBDADE]",
                    "data-[open=true]:border-[#DBDADE]",
                  ],
                }}
                onChange={(e) => {
                  console.log(e.target.value);
                  setSelectedPlans(e.target.value);
                  filterStores("plan_id", e.target.value);
                }}
                items={plans ? plans : []}
              >
                {(item) => (
                  <SelectItem key={item.id} value={item.id}>
                    {item.attributes.title}
                  </SelectItem>
                )}
              </Select>
            </div>
            <div className="flex-1">
              <Select
                variant="bordered"
                size="small"
                placeholder="Paid Status"
                aria-label="status"
                classNames={{
                  trigger: [
                    "rounded-md border border-[#DBDADE] py-[7px] px-[14px]",
                    "data-[hover=true]:border-[#DBDADE]",
                    "data-[focus=true]:border-[#DBDADE]",
                    "data-[open=true]:border-[#DBDADE]",
                  ],
                }}
                onChange={(e) => {
                  console.log(e.target.value);
                  setPaidStatus(e.target.value);
                  filterStores("paid_status", e.target.value);
                }}
              >
                <SelectItem key="unpaid" value="unpaid">
                  Unpaid
                </SelectItem>
                <SelectItem key="paid" value="paid">
                  Paid
                </SelectItem>
              </Select>
            </div>
            <div className="flex-1">
              <input
                type="date"
                className="w-full h-[40px] border border-[#DBDADE] py-[7px] px-[14px] rounded-md focus:outline-none"
                onChange={(e) => {
                  setDate(e.target.value);
                  var parts = e.target.value.split("-");
                  if (parts.length === 3) {
                    filterStores("created_at", e.target.value);
                  }
                }}
              />
            </div>
          </div>
        </div>
        <Divider />
        <div className="flex flex-col sm:flex-row gap-4 px-6">
          <button
            className="flex gap-2 px-[20px] py-[10px] items-center max-sm:justify-center rounded-md shadow-gray-sm bg-primary-500 text-white"
            onClick={openModal}
          >
            <img src={plus} alt="add" />
            <p>Add New User</p>
          </button>
          <button
            className="flex justify-center items-center gap-[6px] px-[20px] py-[10px] rounded-md text-[#A8AAAE] bg-[#a8aaae34]"
            onClick={exportStoresPdf}
          >
            <img src={share} alt="share" />
            <p>Export</p>
          </button>
        </div>
        {!loading && stores && (
          <StoresSingleTable
            stores={stores.data}
            onDeleteStore={deleteStore}
            onUpdateStore={updateStore}
          />
        )}
        {loading && <Loader />}
        {!loading && stores?.meta !== undefined && (
          <div className="px-6 flex flex-col lg:flex-row gap-4 justify-between items-center">
            <p className="w-full flex justify-center lg:justify-start text-[13px] text-[#B8B0CE]">
              Showing {(page - 1) * itemsPerPage + 1} to{" "}
              {(page - 1) * itemsPerPage + stores.data.length} of{" "}
              {stores.meta.total} entries
            </p>
            <div className="w-full flex justify-center lg:justify-end">
              {stores && (
                <CustomPagination
                  page={page}
                  count={Math.ceil(stores.meta.total / itemsPerPage)}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default StoresTable;
