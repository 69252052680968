import { Button, Divider } from "@nextui-org/react";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import logoInvoicePreview from "../../assets/icons/logoInvoicePreview.svg";
import send from "../../assets/icons/send.svg";
import InvoiceItem from "./InvoiceItem";
import PreviewTable from "./PreviewTable";

const AddInvoice = () => {
  const [items, setItems] = useState([
    {
      id: new Date(),
      itemName: "",
      description: "",
      price: 0,
      cost: 0,
      quantity: 1,
      discount: 0,
    },
  ]);

  const addItemHandler = () => {
    setItems((prev) => [
      ...prev,
      {
        id: new Date(),
        itemName: "",
        description: "",
        cost: 0,
        quantity: 1,
        price: 0,
        discount: 0,
      },
    ]);
  };
  const deleteItemHandler = (id) => {
    const itemsData = items;
    const newItems = itemsData.filter((item) => item.id !== id);
    setItems(newItems);
  };

  const updateItemHandler = (id, name, newValue) => {
    const updatedItems = items.map((item) => {
      if (item.id === id) {
        if (name === "cost") {
          return {
            ...item,
            [name]: newValue,
            price:
              newValue && item.quantity
                ? parseFloat(newValue) * parseFloat(item.quantity)
                : 0,
          };
        } else if (name === "quantity") {
          return {
            ...item,
            [name]: newValue,
            price:
              item.cost && newValue
                ? parseFloat(item.cost) * parseFloat(newValue)
                : 0,
          };
        }
        return { ...item, [name]: newValue };
      }
      return item;
    });
    setItems(updatedItems);
    console.log(updatedItems);
  };

  const subtotal = items.reduce(
    (total, item) => total + parseFloat(item.price || 0),
    0
  );

  const discount = items.reduce((total, item) => {
    const discountedPrice = (item.discount / 100) * item.price;
    return total + parseFloat(discountedPrice || 0);
  }, 0);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  let previewing = searchParams.get("preview") === "true";

  return (
    <section className="flex flex-col gap-[26px] pt-[26px]">
      <h3 className="text-[22px] font-semibold leading-[30px] capitalize text-[#a5a2ad]">
        invoice <span className="text-[#5d586c]">/ Add</span>
      </h3>

      <div className="flex min-w-0 w-full items-start gap-[26px] max-lg:flex-col">
        <div className="flex flex-col flex-1 w-full min-w-0 rounded-md shadow-gray-card">
          <div className="w-full min-w-0 p-[40px] flex items-start justify-center gap-6 max-special:flex-col">
            <div className="flex flex-col items-start justify-center w-full gap-6">
              <div className="flex items-center justify-center gap-[10px]">
                <img src={logoInvoicePreview} alt="logo" />
                <p className="text-[22px] font-bold leading-6 capitalize text-[#4B465C]">
                  Daftar
                </p>
              </div>
              <div className="flex flex-col items-start justify-center gap-2">
                <p className="text-[#6f6b7d] text-xs font-medium leading-[22px] capitalize">
                  Office 149, 450 South Brand Brooklyn
                </p>
                <p className="text-[#6f6b7d] text-xs font-medium leading-[22px] capitalize">
                  San Diego County, CA 91905, USA
                </p>
                <p className="text-[#6f6b7d] text-xs font-medium leading-[22px] capitalize">
                  +1 (123) 456 7891, +44 (876) 543 2198
                </p>
              </div>
            </div>
            <div className="flex w-[40%] flex-col items-start justify-center gap-3 max-special:w-full">
              <div className="flex items-start justify-start gap-3 w-[200px] max-special:w-full">
                {previewing ? (
                  <p className="text-[#433f52] text-[22px] font-semibold leading-[30px] capitalize">
                    Invoice #3492
                  </p>
                ) : (
                  <div className=" flex items-center justify-center gap-3 w-[200px] max-md:w-full">
                    <p className="text-[#433f52] text-[22px] font-semibold leading-[30px] capitalize">
                      Invoice
                    </p>
                    <input
                      type="text"
                      name="id"
                      id="id"
                      value={"#3492"}
                      className="w-full border border-[#DBDADE] rounded-md text-[#4B465C] py-[7px] px-[14px] text-[15px] font-semibold leading-6 capitalize"
                      disabled
                    />
                  </div>
                )}
              </div>

              <div className="w-[200px] flex items-start justify-start gap-3 max-special:w-full">
                {previewing ? (
                  <p className="text-[#4B465C] text-xs leading-[22px] capitalize">
                    Date Issues:
                    <span className="text-[15px] font-semibold leading-[22px] capitalize ml-2">
                      25/08/2020
                    </span>
                  </p>
                ) : (
                  <div className="w-[200px] flex items-center justify-center gap-3 max-md:w-full">
                    <p className="text-[#4B465C] text-xs leading-[22px] capitalize min-w-[70px]">
                      Date Issues:
                    </p>
                    <input
                      type="text"
                      name="id"
                      id="id"
                      value={"25/08/2020"}
                      className="w-full border border-[#DBDADE] rounded-md text-[#4B465C] py-[7px] px-[14px] text-[15px] font-semibold leading-6 capitalize"
                    />
                  </div>
                )}
              </div>

              <div className="w-[200px] flex items-center justify-start gap-3 max-special:w-full">
                {previewing ? (
                  <div>
                    <p className="text-[#4B465C] text-xs leading-[22px] capitalize ">
                      Date Due:
                      <span className="text-[15px] font-semibold leading-[22px] capitalize ml-2">
                        29/08/2020
                      </span>
                    </p>
                  </div>
                ) : (
                  <div className="w-[200px] flex items-center justify-center gap-3 max-md:w-full">
                    <p className="text-[#4B465C] text-xs leading-[22px] capitalize min-w-[70px]">
                      Date Due:
                    </p>
                    <input
                      type="text"
                      name="id"
                      id="id"
                      value={"29/08/2020"}
                      className="w-full border border-[#DBDADE] rounded-md text-[#4B465C] py-[7px] px-[14px] text-[15px] font-semibold leading-6 capitalize"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <Divider />

          <div className="w-full p-[40px] flex items-start justify-center gap-6 max-lg:flex-col">
            <div className="flex flex-col items-start justify-center w-full gap-6">
              <p className="text-[#4B465C] text-[15px] font-semibold leading-[21px] capitalize">
                Invoice To:
              </p>
              <div className="flex flex-col items-start justify-center gap-[6px]">
                <p className="text-[#4B465C] text-xs leading-[22px] capitalize">
                  Thomas shelby
                </p>
                <p className="text-[#4B465C] text-xs leading-[22px] capitalize">
                  Shelby Company Limited
                </p>
                <p className="text-[#4B465C] text-xs leading-[22px] capitalize">
                  Small Heath, B10 0HF, UK
                </p>
                <p className="text-[#4B465C] text-xs leading-[22px] capitalize">
                  718-986-6062
                </p>
                <p className="text-[#4B465C] text-xs leading-[22px] capitalize">
                  peakyFBlinders@gmail.com
                </p>
              </div>
            </div>
            <div className="flex flex-col items-start justify-center w-full gap-3">
              <p className="text-[#4B465C] text-[15px] font-semibold leading-[21px] capitalize">
                Bill To:
              </p>
              <div className="flex items-start justify-center gap-6">
                <p className="text-[#4B465C] text-xs leading-[22px] capitalize">
                  Total Due:
                </p>
                <p className="text-[#4B465C] text-xs font-semibold leading-[22px] capitalize">
                  $12,110.55
                </p>
              </div>
            </div>
          </div>

          {previewing ? (
            <PreviewTable items={items} />
          ) : (
            <>
              <Divider />
              <div className="p-[40px] flex flex-col gap-4">
                {items.map((item, index) => (
                  <InvoiceItem
                    key={item.id}
                    item={item}
                    setItems={setItems}
                    index={index}
                    length={items.length}
                    onUpdateItem={updateItemHandler}
                    onDeleteItem={deleteItemHandler}
                  />
                ))}
                {items.length <= 1 && (
                  <div className="pt-[40px]">
                    <Button
                      onClick={addItemHandler}
                      className="bg-primary-500 text-white text-[13px] py-[10px] px-[20px] rounded-[4px] shadow-gray-card"
                    >
                      Add Item
                    </Button>
                  </div>
                )}
              </div>
              <Divider />
            </>
          )}

          <div className="w-full p-[40px] flex items-start justify-between gap-6 max-lg:flex-col">
            <div className="flex flex-col items-start justify-center gap-2">
              <p
                className={`text-[#4B465C] text-[15px] font-semibold leading-[22px] capitalize ${
                  previewing ? "flex items-center justify-center gap-3" : ""
                }`}
              >
                Salesperson:
                {previewing ? (
                  <span className="text-xs leading-[22px]">ibrahim said</span>
                ) : (
                  <input
                    type="text"
                    name="id"
                    id="id"
                    value={"ibrahim said"}
                    className="w-full border border-[#DBDADE] rounded-md text-[#4B465C] py-[7px] px-[14px] font-normal text-[15px] leading-6 capitalize"
                  />
                )}
              </p>

              {previewing ? (
                <p className="text-[#4B465C] text-xs leading-[22px] capitalize">
                  Thanks for your business
                </p>
              ) : (
                <input
                  type="text"
                  name="id"
                  id="id"
                  value={"Thanks for your business"}
                  className="w-full border border-[#DBDADE] rounded-md text-[#4B465C] py-[7px] px-[14px] text-[15px] leading-6 capitalize"
                />
              )}
            </div>
            <div className="w-[200px] flex flex-col gap-2">
              <div className="flex items-center justify-center w-full gap-6">
                <p className="text-[#4B465C] text-xs leading-[22px] capitalize w-full text-end ">
                  Subtotal:
                </p>
                <p className="text-[#4B465C] text-[15px] font-semibold leading-[22px] capitalize">
                  EGP {subtotal.toFixed(2)}
                </p>
              </div>
              <div className="flex items-center justify-center w-full gap-6">
                <p className="text-[#4B465C] text-xs leading-[22px] capitalize w-full text-end">
                  Discount:
                </p>
                <p className="text-[#4B465C] text-[15px] font-semibold leading-[22px] capitalize">
                  EGP {discount.toFixed(2)}
                </p>
              </div>
              <div className="flex items-center justify-center w-full gap-6">
                <p className="text-[#4B465C] text-xs leading-[22px] capitalize w-full text-end">
                  Tax:
                </p>
                <p className="text-[#4B465C] text-[15px] font-semibold leading-[22px] capitalize">
                  EGP 50.00
                </p>
              </div>
              <div className="flex items-center justify-center w-full gap-6">
                <p className="text-[#4B465C] text-xs leading-[22px] capitalize w-full text-end">
                  Total:
                </p>
                <p className="text-[#4B465C] text-[15px] font-semibold leading-[22px] capitalize">
                  EGP {subtotal + 50 - discount}
                </p>
              </div>
            </div>
          </div>

          <Divider />

          <div className="w-full flex items-center justify-center p-[40px] ">
            <div className="flex flex-col items-start justify-center w-full h-full gap-1">
              <p className="text-[15px] font-semibold text-[#4B465C] leading-[22px] capitalize">
                Note:
              </p>

              {previewing ? (
                <span className="text-xs">
                  It was a pleasure working with you and your team. We hope you
                  will keep us in mind for future freelance projects. Thank You!
                </span>
              ) : (
                <textarea
                  type="text"
                  placeholder="Massage"
                  className="w-full h-[70px] p-[10px] rounded border border-[#DBDADE] bg-transparent focus:outline-none resize-none"
                />
              )}
            </div>
          </div>
        </div>

        <div className="w-[262px] flex p-6 flex-col items-center justify-center gap-2 rounded-md shadow-gray-card max-lg:w-full">
          <Button
            startContent={<img src={send} alt="send" />}
            disabled
            className="w-full flex items-center justify-center bg-primary-500 text-white text-[15px] font-medium tracking-[0.43px] capitalize py-[10x] px-[47px] rounded-md shadow-gray-sm cursor-not-allowed"
          >
            Send Invoice
          </Button>

          {previewing ? (
            <Button
              className="w-full bg-[#f1f1f2] text-[#A8AAAE] text-[15px] font-medium tracking-[0.43px] capitalize py-[10x] px-[47px] rounded-md"
              onClick={() => navigate("")}
            >
              Edit
            </Button>
          ) : (
            <Button
              className="w-full bg-[#f1f1f2] text-[#A8AAAE] text-[15px] font-medium tracking-[0.43px] capitalize py-[10x] px-[47px] rounded-md"
              onClick={() => navigate("?preview=true")}
            >
              Preview
            </Button>
          )}
          <Button className="w-full bg-[#f1f1f2] text-[#A8AAAE] text-[15px] font-medium tracking-[0.43px] capitalize py-[10x] px-[47px] rounded-md">
            Save
          </Button>
        </div>
      </div>
    </section>
  );
};

export default AddInvoice;
