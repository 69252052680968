import React from "react";
import { Outlet } from "react-router";
import SingleStore from "../components/single-store/SingleStore";

const SingleStorePage = () => {
  return (
    <SingleStore >
      <Outlet />
    </SingleStore>
  );
};

export default SingleStorePage;
