import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
// import RightArrowIcon from './icons/RightArrowIcon';

const MenuItem = ({ name, url, icon, activeIcon, collapse, onClickHandler }) => {
    const location = useLocation();
    let active = location.pathname.includes(url);
    if (url === '/') {
        active = location.pathname === url
    }
    return (
        <Link
            to={url}
            className={`flex justify-between items-center group-[.collapse-menu]:justify-center rounded-md px-[16px] py-[9px] ${active && 'bg-gradient-to-r from-[#F0A167] to-[#F0C167B2]/70'}`}
            onClick={() => onClickHandler && onClickHandler()}
        >
            <div className='flex gap-[10px] items-center'>
                {active ? activeIcon : icon}
                <motion.h1
                    animate={{ display: collapse ? 'none' : 'block' }}
                    transition={{ duration: 0.15 }}
                    className={`font-semibold text-xs leading-[22px] ${active ? 'text-white' : 'text-[#4B465C]'}`}>
                    {name}
                </motion.h1>
            </div>
        </Link>
    );
};

export default MenuItem;
