import AccountIcon from "../components/ui/icons/AccountIcon";
import DollarIcon from "../components/ui/icons/DollarIcon";
import BellIcon from "../components/ui/icons/BellIcon";
import PhoneIcon from "../components/ui/icons/PhoneIcon";

export const tabs = [
    {
        id: 'account',
        label: "Account",
        icon: <AccountIcon color="#A8AAAE" />,
        activeIcon: <AccountIcon color="#fff" />,
        route: "account",
    },
    {
        id: 'billing',
        label: "Billing",
        icon: <DollarIcon color="#A8AAAE" />,
        activeIcon: <DollarIcon color="#fff" />,
        route: "billing",
    },
    {
        id: 'notifications',
        label: "Notifications",
        icon: <BellIcon color="#A8AAAE" />,
        activeIcon: <BellIcon color="#fff" />,
        route: "notifications",
    },
    {
        id: 'plan',
        label: "App pages & Plan",
        icon: <PhoneIcon color="#A8AAAE" />,
        activeIcon: <PhoneIcon color="#fff" />,
        route: "plan",
    },
];