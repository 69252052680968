import React, { useEffect, useState } from "react";
import SingleWallet from "./SingleWallet";
import { getWallets } from "../../api/AppManagement";
import Loader from "../ui/Loader";

const WALLETS = [
  {
    name: "All",
    amount: 6000,
  },
  {
    name: "Vodafone Cash",
    amount: 1500,
  },
  {
    name: "Orange Cash",
    amount: 1500,
  },
  {
    name: "Etisalat Cash",
    amount: 1500,
  },
];
const Wallets = () => {
//   const [wallets, setWallets] = useState();
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const getWalletsData = async (id) => {
//       const { data } = await getWallets(id);
//       const formattedWallets = transformWalletsData(data);
//       setWallets(formattedWallets);
//       setLoading(false);
//     };
//     getWalletsData();
//   }, []);

//   const transformWalletsData = (data) => {
//     const allWallet = {
//       name: "All",
//       amount: data.total_billing,
//     };
//     const specificWallets = data.payment_method.map((method) => ({
//       name: method.payment_name,
//       amount: method.status === 1 ? data.total_billing : 0,
//     }));

//     return [allWallet, ...specificWallets];
//   };

  return (
    <div className="flex gap-[26px] flex-wrap">
      {WALLETS.map((wallet) => (
        <SingleWallet
          key={wallet.name}
          name={wallet.name}
          amount={wallet.amount}
        />
      ))}
    </div>
  );
};

export default Wallets;
