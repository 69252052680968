import React, { useEffect, useState } from "react";
import AdminRolesTable from "./AdminRolesTable";
import ModalRole from "./ModalRole";
import ModalRoleContent from "./ModalRoleContent";
import { useSearchParams } from "react-router-dom";
import { addRole, getRoles } from "../../api/DashboardManagement";
import { toast } from "../toast/Toast";

const AdminRole = () => {
  const [roles, setRoles] = useState();

  const [isModelOpen, setIsModelOpen] = useState(false);

  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  let page = parseInt(searchParams.get("page")) || 1;

  const [name, setName] = useState("");
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    const getRolesData = async () => {
      setLoading(true);
      const { data } = await getRoles(page);
      setRoles(data);
      setLoading(false);
    };
    getRolesData();
  }, [page]);

  const openModal = () => {
    setIsModelOpen(true);
  };

  const closeModal = () => {
    setIsModelOpen(false);
  };

  const addRoleHandler = async () => {
    try {
      if (permissions.length <= 0) {
        toast("Select Permissions");
        return;
      }

      const { data } = await addRole({
        name,
        permissions,
      });
      console.log(data);
      if (roles.data.length < 10) {
        setRoles((prev) => {
          return {
            ...prev,
            data: [data.data, ...prev.data],
            meta: { ...prev.meta, total: prev.meta.total + 1 },
          };
        });
        closeModal();
      }
    } catch (error) {
      toast(error);
    }
  };

  return (
    <section className="flex flex-col gap-[26px] pt-[26px]">
      <h3 className="page-heading">Roles List</h3>
      {roles && !loading && (
        <AdminRolesTable
          openModal={openModal}
          roles={roles}
          setRoles={setRoles}
          page={page}
          name={name}
          setName={setName}
          permissions={permissions}
          setPermissions={setPermissions}
        />
      )}

      <ModalRole
        isOpen={isModelOpen}
        onClose={closeModal}
        onAddRoleHandler={addRoleHandler}
        title={"Add New Role"}
      >
        <ModalRoleContent
          closeModal={closeModal}
          name={name}
          setName={setName}
          setPermissions={setPermissions}
        />
      </ModalRole>
    </section>
  );
};

export default AdminRole;
