import React from 'react';

const DashboardIcon = ({color}) => {
    return (
        <svg
            width='22'
            height='22'
            viewBox='0 0 22 22'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M17.4168 7.98428L12.5283 4.18195C11.6457 3.49534 10.4097 3.49534 9.52709 4.18195L4.63759 7.98428C4.04212 8.44735 3.69398 9.15954 3.69434 9.91386V16.5139C3.69434 17.5264 4.51515 18.3472 5.52767 18.3472H16.5277C17.5402 18.3472 18.361 17.5264 18.361 16.5139V9.91386C18.361 9.15945 18.0127 8.4472 17.4168 7.98428'
                stroke={color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M17.4168 7.98428L12.5283 4.18195C11.6457 3.49534 10.4097 3.49534 9.52709 4.18195L4.63759 7.98428C4.04212 8.44735 3.69398 9.15954 3.69434 9.91386V16.5139C3.69434 17.5264 4.51515 18.3472 5.52767 18.3472H16.5277C17.5402 18.3472 18.361 17.5264 18.361 16.5139V9.91386C18.361 9.15945 18.0127 8.4472 17.4168 7.98428'
                stroke='white'
                strokeOpacity='0.1'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M14.6663 13.7501C12.6405 14.972 9.35701 14.972 7.33301 13.7501'
                stroke={color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M14.6663 13.7501C12.6405 14.972 9.35701 14.972 7.33301 13.7501'
                stroke='white'
                strokeOpacity='0.1'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export default DashboardIcon;
