import React, { useState } from 'react';
import Input from '../ui/Input';
import { Button } from '@nextui-org/react';
import BorderedCheckbox from '../ui/BorderedCheckbox';
import { addPackage, getAppPages } from '../../api/DashboardManagement';
import { useEffect } from 'react';

const PackageModal = ({ closeModal, setNewPackage }) => {
  const [name, setName] = useState('');
  const [numberUsers, setNumberUsers] = useState('');
  const [price, setPrice] = useState('');
  const [description, setDescription] = useState('');
  const [pages, setPages] = useState();
  const [checkedIDs, setCheckedIDs] = useState([]);

  useEffect(() => {
    const getAppPagesData = async () => {
      const { data } = await getAppPages();
      console.log(data);
      setPages(data.data);
    };
    getAppPagesData();
  }, []);

  const addPackageHandler = async () => {
    const { data } = await addPackage({
      name,
      num_user: parseInt(numberUsers),
      price: parseInt(price),
      description,
      page_ids: checkedIDs,
    });
    console.log(data);
    setNewPackage(data.data);
    closeModal();
  };

  const handleCheckboxChange = (event) => {
    const { checked, value } = event.target;
    if (checked) {
      setCheckedIDs(prevState => [...prevState, value]);
    } else {
      setCheckedIDs(prevState => prevState.filter(item => item !== value));
    }
    console.log(checkedIDs);
  };

  return (
    <div className='flex flex-col gap-4'>
      <Input
        title='Package Name'
        type='text'
        name='package'
        placeholder='Basic Package'
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <Input
        title='Number Users'
        type='number'
        name='users_number'
        placeholder='10'
        value={numberUsers}
        onChange={(e) => setNumberUsers(e.target.value)}
      />
      <div className='flex flex-col gap-1'>
        <p className='text-[13px] capitalize font-normal text-[#4B465C]'>
          Description
        </p>
        <textarea
          rows={3}
          placeholder='...'
          className='w-full flex text-md border border-[#DBDADE] resize-x-none rounded-md text-black py-[7px] px-[14px] focus:outline-none'
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </div>
      <div className='flex flex-col gap-3'>
        {pages?.map((page) => (
          <BorderedCheckbox
            key={page.id}
            id={page.id}
            label={page.attribute.title}
            value={page.id}
            onChangeHandler={handleCheckboxChange}
          />
        ))}
      </div>
      <Input
        title='Package Price'
        type='number'
        name='package_price'
        placeholder='1500'
        value={price}
        onChange={(e) => setPrice(e.target.value)}
      />
      <div className='flex items-start gap-4 mt-[10px]'>
        <Button
          size='sm'
          className='w-max bg-primary-500 text-white text-sm rounded-md py-[10px] px-[20px] shadow-[0px_2px_4px_0px_rgba(165, 163, 174, 0.30)] capitalize'
          onClick={addPackageHandler}
        >
          Add
        </Button>

        <Button
          size='sm'
          className='w-max bg-secondary-200 text-secondary-500 text-sm rounded-md py-[10px] px-[20px] shadow-[0px_2px_4px_0px_rgba(165, 163, 174, 0.30)] capitalize'
          onClick={closeModal}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default PackageModal;
