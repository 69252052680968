import React from 'react';

const CartIcon = ({color}) => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='22'
            height='22'
            viewBox='0 0 22 22'
            fill='none'
        >
            <ellipse
                cx='5.50033'
                cy='17.4168'
                rx='1.83333'
                ry='1.83333'
                stroke={color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <ellipse
                cx='5.50033'
                cy='17.4168'
                rx='1.83333'
                ry='1.83333'
                stroke='white'
                strokeOpacity='0.2'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <ellipse
                cx='15.5833'
                cy='17.4168'
                rx='1.83333'
                ry='1.83333'
                stroke={color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <ellipse
                cx='15.5833'
                cy='17.4168'
                rx='1.83333'
                ry='1.83333'
                stroke='white'
                strokeOpacity='0.2'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M15.5837 15.5833H5.50033V2.75H3.66699'
                stroke={color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M15.5837 15.5833H5.50033V2.75H3.66699'
                stroke='white'
                strokeOpacity='0.2'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M5.5 4.5835L18.3333 5.50016L17.4167 11.9168H5.5'
                stroke={color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M5.5 4.5835L18.3333 5.50016L17.4167 11.9168H5.5'
                stroke='white'
                strokeOpacity='0.2'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export default CartIcon;
