import React, { useState } from "react";
import CustomPagination from "../ui/CustomPagination";
import { Button, Select, SelectItem } from "@nextui-org/react";
import PlusBtn from "../ui/icons/PlusBtn";
import PaymentModal from "./PaymentModal";
import Modal from "../ui/Modal";
import PaymentsSingleTable from "./PaymentsSingleTable";
import { useEffect } from "react";
import {
  getFilteredPayments,
  getPayments,
} from "../../api/DashboardManagement";
import Loader from "../ui/Loader";
import { useSearchParams } from "react-router-dom";

const itemsPerPage = 10;

const PaymentsTable = ({ newPayment, setNewPayment }) => {
  const [searchParams] = useSearchParams();
  let page = searchParams.get("page") || 1;
  const [payments, setPayments] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchInput, setSearchInput] = useState("");

  // useEffect(() => {
  //   const timeOut = setTimeout(() => {
  //     setSearchTerm(searchInput);
  //   }, 500);
  //   return () => clearTimeout(timeOut);
  // }, [searchInput]);

  // useEffect(() => {
  //   setLoading(true);
  //   const getSearchStoresData = async () => {
  //     const { data } = await searchStores(searchTerm);
  //     console.log("search", data);
  //     setStores(data);
  //     setLoading(false);
  //   };
  //   if (searchTerm.trim().length > 0) {
  //     getSearchStoresData();
  //   }
  // }, [searchTerm]);

  useEffect(() => {
    const getPaymentsData = async () => {
      setLoading(true);
      const { data } = await getPayments(page);
      setPayments(data);
      console.log(data);
      setLoading(false);
    };
    if (selectedStatus.trim().length === 0) {
      getPaymentsData();
    }
  }, [page, selectedStatus]);

  const [isModelOpen, setIsModelOpen] = useState(false);

  const openModal = () => {
    setIsModelOpen(true);
  };

  const closeModal = () => {
    setIsModelOpen(false);
  };

  useEffect(() => {
    if (newPayment) {
      if (payments.data.length < 10) {
        setPayments((prev) => {
          return {
            ...prev,
            data: [newPayment, ...prev.data],
            meta: { ...prev.meta, total: prev.meta.total + 1 },
          };
        });
      }
    }
  }, [newPayment]);

  const deletePayment = (id) => {
    setPayments((prev) => {
      const updatedPayments = payments.data.filter(
        (product) => product.id !== id
      );
      return { ...prev, data: updatedPayments };
    });
  };

  const updatePayment = (newData) => {
    const updatedPaymnetIndex = payments.data.findIndex(
      (payment) => parseInt(payment.id) === parseInt(newData.id)
    );
    const updatedPayments = payments.data;
    updatedPayments[updatedPaymnetIndex] = newData;
    setPayments((prev) => {
      return { ...prev, data: [...updatedPayments] };
    });
  };

  const filterPayments = async (name, value) => {
    if (value.trim().length > 0) {
      let filter = {};
      filter[name] = value;
      setLoading(true);
      const { data } = await getFilteredPayments(filter, page);
      // console.log('filter', date, data);
      setPayments(data);
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col w-full gap-4 py-6 bg-white rounded-md shadow-gray-card">
      <div className="flex items-center justify-between w-full px-6 py-4 max-lg:flex-col max-lg:gap-4">
        <div className="max-lg:w-full flex max-lg:flex-col gap-[10px] items-center justify-center">
          <Button
            startContent={<PlusBtn />}
            className="max-lg:w-full bg-primary-500 text-white text-[13px] py-[6px] px-[14px] rounded-[4px] shadow-[0_2_4_0px]"
            onClick={openModal}
          >
            Create Payment
          </Button>
        </div>
        <div className="flex gap-4 max-lg:w-full max-lg:flex-col">
          <input
            placeholder="Search payment"
            className="focus:outline-none flex-1 text-[15px] border border-[#DBDADE] py-[7px] px-[14px] rounded-md"
          />
          <Select
            variant="bordered"
            size="small"
            placeholder="Status"
            aria-label="Status"
            classNames={{
              trigger:
                "dropdown min-w-[160px] w-full data-[hover=true]:border-[#DBDADE] data-[focus=true]:border-[#DBDADE] data-[open=true]:border-[#DBDADE]",
            }}
            onChange={(e) => {
              setSelectedStatus(e.target.value);
              filterPayments("status", e.target.value);
            }}
          >
            <SelectItem key="1" value="1">
              Active
            </SelectItem>
            <SelectItem key="0" value="0">
              Inactive
            </SelectItem>
          </Select>
        </div>
      </div>
      <div>
        {!loading && payments && (
          <PaymentsSingleTable
            payments={payments.data}
            onDeletePayment={deletePayment}
            onUpdatePayment={updatePayment}
          />
        )}
        {loading && <Loader />}
      </div>
      {!loading && payments?.meta !== undefined && (
        <div className="flex flex-col items-center justify-between gap-4 px-6 lg:flex-row">
          <p className="w-full flex justify-center lg:justify-start text-[13px] text-[#B8B0CE]">
            Showing {(page - 1) * itemsPerPage + 1} to{" "}
            {(page - 1) * itemsPerPage + payments.data.length} of{" "}
            {payments.meta.total} entries
          </p>
          <div className="flex justify-center w-full lg:justify-end">
            <CustomPagination
              page={page}
              count={Math.ceil(payments.meta.total / itemsPerPage)}
            />
          </div>
        </div>
      )}
      <Modal isOpen={isModelOpen} onClose={closeModal} title="Add Payments">
        <PaymentModal closeModal={closeModal} setNewPayment={setNewPayment} />
      </Modal>
    </div>
  );
};

export default PaymentsTable;
