import React from 'react'
import dots from '../../assets/icons/dots-vertical.svg'
const SingleWallet = ({ name, amount }) => {
    return (
        <div className='flex-1 p-6 rounded-md shadow-gray-card bg-white flex flex-col gap-10'>
            <div className='flex justify-between h-6'>
                <h3 className='text-[18px] font-semibold leading-6 text-[#4B465C]'>{name} Wallet</h3>
                <img src={dots} alt={name} className='cursor-pointer'/>
            </div>
            <p className='text-[38px] font-semibold leading-[52px] text-[#4B465C]'>EGP {amount}</p>
        </div>
    )
}

export default SingleWallet