import React, { useEffect, useState } from "react";
import CustomPagination from "../ui/CustomPagination";
import { Button, Select, SelectItem } from "@nextui-org/react";
import PlusBtn from "../ui/icons/PlusBtn";
import SelectComponent from "../ui/SelectComponent";
import Modal from "../ui/Modal";
import StaffModal from "./StaffModal";
import StaffSingleTable from "./StaffSingleTable";
import {
  getFilteredStaff,
  getRoles,
  getStaff,
  searchStaff,
} from "../../api/DashboardManagement";
import Loader from "../ui/Loader";
import { useSearchParams } from "react-router-dom";
import { toast } from "../toast/Toast.js";

const itemsPerPage = 10;

const StaffTable = () => {
  const [staff, setStaff] = useState();
  const [loading, setLoading] = useState(false);

  const [searchParams] = useSearchParams();
  let page = parseInt(searchParams.get("page")) || 1;

  const [searchTerm, setSearchTerm] = useState("");
  const [searchInput, setSearchInput] = useState("");

  const [selectedNum, setSelectedNum] = useState(10);

  const [isModelOpen, setIsModelOpen] = useState(false);

  const [newStaff, setNewStaff] = useState();

  const [selectedRole, setSelectedRole] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");

  const [loadingRoles, setLoadingRoles] = useState(false);

  const [roles, setRoles] = useState();

  const openModal = () => {
    setIsModelOpen(true);
  };

  const closeModal = () => {
    setIsModelOpen(false);
  };

  useEffect(() => {
    const timeOut = setTimeout(() => {
      setSearchTerm(searchInput);
    }, 500);
    return () => clearTimeout(timeOut);
  }, [searchInput]);

  useEffect(() => {
    try {
      setLoading(true);
      const getSearchStaffData = async () => {
        const { data } = await searchStaff(searchTerm);
        console.log("search", data);
        setStaff(data);
        setLoading(false);
      };
      if (searchTerm.trim().length > 0) {
        getSearchStaffData();
      }
    } catch (error) {
      toast(error);
    }
  }, [searchTerm]);

  useEffect(() => {
    try {
      const getStaffData = async () => {
        setLoading(true);
        const { data } = await getStaff();
        setStaff(data);
        console.log("data", data);
        setLoading(false);
      };
      if (
        searchTerm.trim().length === 0 &&
        selectedRole.trim().length === 0 &&
        selectedStatus.trim().length === 0
      ) {
        getStaffData();
      }
    } catch (error) {
      toast(error);
    }
  }, [searchTerm, selectedRole, selectedStatus]);

  const deleteStaff = (id) => {
    try {
      setStaff((prev) => {
        const updatedStaff = prev.data.filter((staff) => staff.id !== id);
        return {
          ...prev,
          data: updatedStaff,
          meta: { ...prev.meta, total: prev.meta.total - 1 },
        };
      });
    } catch (error) {
      toast(error);
    }
  };

  useEffect(() => {
    if (newStaff) {
      if (staff.data.length < 10) {
        setStaff((prev) => {
          return {
            ...prev,
            data: [newStaff, ...prev.data],
            meta: { ...prev.meta, total: prev.meta.total + 1 },
          };
        });
      }
    }
  }, [newStaff]);

  const updateStaff = (newData) => {
    const updatedStaffIndex = staff.data.findIndex(
      (staff) => parseInt(staff.id) === parseInt(newData.id)
    );
    const updatedStaffs = staff.data;
    updatedStaffs[updatedStaffIndex] = newData;
    setStaff((prev) => {
      return { ...prev, data: [...updatedStaffs] };
    });
  };

  const filterStaff = async (name, value) => {
    if (value.trim().length > 0) {
      let filter = {};
      filter[name] = value;
      setLoading(true);
      const { data } = await getFilteredStaff(filter);
      console.log("filter", selectedStatus, data);
      setStaff(data);
      setLoading(false);
    }
  };

  useEffect(() => {
    try {
      const getRolesData = async () => {
        setLoadingRoles(true);
        const { data } = await getRoles();
        console.log(data);
        setRoles(data.data);
        setLoadingRoles(false);
      };
      getRolesData();
    } catch (error) {
      toast(error);
    }
  }, []);

  return (
    <div className="flex flex-col w-full gap-4 py-6 bg-white rounded-md shadow-gray-card">
      <div className="flex flex-col items-center justify-between w-full gap-4 px-6 py-4">
        <div className="max-lg:w-full flex max-lg:flex-col gap-[10px] items-center justify-center">
          <Button
            startContent={<PlusBtn />}
            className="max-lg:w-full bg-primary-500 text-white text-[13px] py-[6px] px-[14px] rounded-[4px] shadow-[0_2_4_0px]"
            onClick={openModal}
          >
            Add New Member
          </Button>
        </div>
        <div className="flex w-full gap-4 max-lg:flex-col">
          <div className="w-full">
            <input
              placeholder="Search payment"
              className="focus:outline-none w-full text-[15px] border border-[#DBDADE] py-[7px] px-[14px] rounded-md"
              onChange={(e) => setSearchInput(e.target.value)}
              value={searchInput}
            />
          </div>
          <div className="w-full">
            <Select
              variant="bordered"
              size="small"
              placeholder="Select Role"
              aria-label="Select Role"
              isLoading={loadingRoles}
              classNames={{
                trigger: [
                  "rounded-md border border-[#DBDADE] py-[7px] px-[14px]",
                  "data-[hover=true]:border-[#DBDADE]",
                  "data-[focus=true]:border-[#DBDADE]",
                  "data-[open=true]:border-[#DBDADE]",
                ],
              }}
              onChange={(e) => {
                console.log(e.target.value);
                setSelectedRole(e.target.value);
                filterStaff("role", e.target.value);
              }}
              items={roles ? roles : []}
            >
              {(item) => (
                <SelectItem key={item.id} value={item.id}>
                  {item.attribute.name}
                </SelectItem>
              )}

              {/* {selectedRole &&
                selectedRole?.map((item, i) => {
                  return (
                    <SelectItem key={i} value={item.id}>
                      {item.attribute.name}
                    </SelectItem>
                  );
                })} */}
            </Select>
          </div>
          <div className="w-full">
            <Select
              variant="bordered"
              size="small"
              placeholder="Status"
              aria-label="Status"
              classNames={{
                trigger:
                  "dropdown data-[hover=true]:border-[#DBDADE] data-[focus=true]:border-[#DBDADE] data-[open=true]:border-[#DBDADE]",
              }}
              onChange={(e) => {
                setSelectedStatus(e.target.value);
                filterStaff("status", e.target.value);
              }}
            >
              <SelectItem key="1" value="1">
                Active
              </SelectItem>
              <SelectItem key="0" value="0">
                Inactive
              </SelectItem>
            </Select>
          </div>
        </div>
      </div>

      {!loading && staff && (
        <StaffSingleTable
          staff={staff.data}
          onDeleteStaff={deleteStaff}
          onUpdateStaff={updateStaff}
        />
      )}
      {loading && <Loader />}

      {!loading && staff?.meta !== undefined && (
        <div className="flex flex-col items-center justify-between gap-4 px-6 lg:flex-row">
          <p className="w-full flex justify-center lg:justify-start text-[13px] text-[#B8B0CE]">
            Showing {(page - 1) * itemsPerPage + 1} to{" "}
            {(page - 1) * itemsPerPage + staff.data.length} of{" "}
            {staff.meta.total} entries
          </p>
          <div className="flex justify-center w-full lg:justify-end">
            <CustomPagination />
          </div>
        </div>
      )}
      <Modal isOpen={isModelOpen} onClose={closeModal} title="Add New Member">
        <StaffModal closeModal={closeModal} setNewStaff={setNewStaff} />
      </Modal>
    </div>
  );
};

export default StaffTable;
