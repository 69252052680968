import React from "react";

const DollarIcon = ({ color = "#A8AAAE" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M12.525 6C12.2196 5.13344 11.418 4.53964 10.5 4.5H7.5C6.25736 4.5 5.25 5.50736 5.25 6.75C5.25 7.99264 6.25736 9 7.5 9H10.5C11.7426 9 12.75 10.0074 12.75 11.25C12.75 12.4926 11.7426 13.5 10.5 13.5H7.5C6.58205 13.4604 5.78041 12.8666 5.475 12"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.75 2.25C9.75 1.83579 9.41421 1.5 9 1.5C8.58579 1.5 8.25 1.83579 8.25 2.25H9.75ZM8.25 4.5C8.25 4.91421 8.58579 5.25 9 5.25C9.41421 5.25 9.75 4.91421 9.75 4.5H8.25ZM9.75 13.5C9.75 13.0858 9.41421 12.75 9 12.75C8.58579 12.75 8.25 13.0858 8.25 13.5H9.75ZM8.25 15.75C8.25 16.1642 8.58579 16.5 9 16.5C9.41421 16.5 9.75 16.1642 9.75 15.75H8.25ZM8.25 2.25V4.5H9.75V2.25H8.25ZM8.25 13.5V15.75H9.75V13.5H8.25Z"
        fill={color}
      />
    </svg>
  );
};

export default DollarIcon;
