import React, { useState } from "react";
import ReasonsTable from "./ReasonsTable";
import ModalReasonsContent from "./ModalReasonsContent";
import Modal from "../ui/Modal";

const Reasons = () => {
  const [newReason, setNewReason] = useState();
  const [isModelOpen, setIsModelOpen] = useState(false);

  const openModal = () => {
    setIsModelOpen(true);
  };

  const closeModal = () => {
    setIsModelOpen(false);
  };

  return (
    <section className="flex flex-col gap-[26px] pt-[26px]">
      <h3 className="page-heading">Expense's Reasons</h3>

      <ReasonsTable openModal={openModal} newReason={newReason} />

      <Modal isOpen={isModelOpen} onClose={closeModal} title="Add New Reason">
        <ModalReasonsContent
          closeModal={closeModal}
          setNewReason={setNewReason}
        />
      </Modal>
    </section>
  );
};

export default Reasons;
