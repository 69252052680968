import React from "react";

const Footer = () => {
  return (
    <div className="flex items-center py-3 justify-between">
      <p className="text-[#4B465C] text-xs ">© 2023, made with ❤️ by Softa</p>
      <p className="text-[#4B465C] text-xs">Support</p>
    </div>
  );
};

export default Footer;
