import React, { useEffect, useRef } from 'react'
import search from '../../assets/icons/search.svg'
import bell from '../../assets/icons/bell.svg'
import avatar from '../../assets/icons/avatar.svg'
import MobileMenu from './MobileMenu'
const SearchBar = () => {
    const inputRef = useRef()

    useEffect(() => {
        window.addEventListener('keydown', function (event) {
            if (event.ctrlKey && event.key === '/') {
                inputRef.current.focus()
                event.preventDefault()
            }
        })
    }, [])

    return (
        <div className='flex items-center px-6 py-3 gap-[10px] shadow-gray-sm rounded-md sm:mt-4 bg-white'>
            <MobileMenu />
            <img src={search} alt='search' className='w-[26px] h-[26px] cursor-pointer' />
            <input className='flex-1 text-[12px] focus:outline-none' placeholder='Search (Ctrl + /) ' ref={inputRef} />
            <img src={bell} alt='notifiactions' className='w-[26px] h-[26px] cursor-pointer' />
            <img src={avatar} alt='avatar' className='w-[38px] h-[38px] cursor-pointer' />
        </div>
    )
}

export default SearchBar