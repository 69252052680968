import React from 'react';

const PackagesIcon = ({ color }) => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='22'
            height='22'
            viewBox='0 0 22 22'
            fill='none'
        >
            <path
                d='M3.66699 11.9167C7.03134 12.3165 9.68383 14.969 10.0837 18.3334C11.731 17.3837 12.7709 15.6504 12.8337 13.75C15.9813 12.6427 18.152 9.74845 18.3337 6.41669C18.3337 4.8979 17.1024 3.66669 15.5837 3.66669C12.2519 3.84835 9.35761 6.01906 8.25033 9.16669C6.3499 9.22945 4.61669 10.2694 3.66699 11.9167'
                stroke={color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M3.66699 11.9167C7.03134 12.3165 9.68383 14.969 10.0837 18.3334C11.731 17.3837 12.7709 15.6504 12.8337 13.75C15.9813 12.6427 18.152 9.74845 18.3337 6.41669C18.3337 4.8979 17.1024 3.66669 15.5837 3.66669C12.2519 3.84835 9.35761 6.01906 8.25033 9.16669C6.3499 9.22945 4.61669 10.2694 3.66699 11.9167'
                stroke='white'
                strokeOpacity='0.2'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M6.41638 12.8333C4.46474 13.9351 3.37684 16.1109 3.66638 18.3333C5.88877 18.6229 8.06457 17.5349 9.16638 15.5833'
                stroke={color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M6.41638 12.8333C4.46474 13.9351 3.37684 16.1109 3.66638 18.3333C5.88877 18.6229 8.06457 17.5349 9.16638 15.5833'
                stroke='white'
                strokeOpacity='0.2'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <ellipse
                cx='13.7497'
                cy='8.24998'
                rx='0.916667'
                ry='0.916667'
                stroke={color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <ellipse
                cx='13.7497'
                cy='8.24998'
                rx='0.916667'
                ry='0.916667'
                stroke='white'
                strokeOpacity='0.2'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export default PackagesIcon;
