import { Button } from "@nextui-org/button";
import { Select, SelectItem } from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import { addSubUser } from "../../api/AppManagement";
import { getRoles } from "../../api/DashboardManagement";
import Input from "../ui/Input";
import { toast } from "../toast/Toast";

const AddSubUsersModal = ({ closeModal, setNewSubUser, storeId }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [role, setRole] = useState("");
  const [roles, setRoles] = useState();

  const addSubUserHandler = async () => {
    try {
      const { data } = await addSubUser({
        name,
        email,
        password,
        role,
        phone: "112",
        type_id: "1",
        vendor_id: storeId,
      });
      closeModal();
      setNewSubUser(data.data);
      console.log(data);
    } catch (error) {
      toast(error);
    }
  };

  useEffect(() => {
    const getRolesData = async () => {
      const { data } = await getRoles();
      // console.log(data);
      setRoles(data.data);
    };
    getRolesData();
  }, []);

  return (
    <div className="flex flex-col gap-4">
      <Input
        title="Store Name"
        type="text"
        name="name"
        placeholder="John Doe"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />

      <Input
        title="Email"
        type="email"
        name="email"
        placeholder="John.Doe@gmail.com"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />

      <Input
        title="Password"
        type="password"
        name="password"
        placeholder="********"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />

      <Input
        title="Confirm Password"
        type="password"
        name="confirmPassword"
        placeholder="********"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
      />

      <div className="flex flex-col items-start gap-1 w-full h-[113px]">
        <label className="text-[13px] capitalize font-normal text-[#4B465C]">
          Role
        </label>
        <div className="flex items-start justify-center w-full h-full gap-1">
          <Select
            variant="bordered"
            size="small"
            placeholder="Select Store"
            aria-label="Select Store"
            classNames={{
              trigger: [
                "rounded-md border border-[#DBDADE] py-[7px] px-[14px]",
                "data-[hover=true]:border-[#DBDADE]",
                "data-[focus=true]:border-[#DBDADE]",
                "data-[open=true]:border-[#DBDADE]",
              ],
            }}
            onChange={(e) => {
              console.log(e.target.value);
              setRole(e.target.value);
            }}
            items={roles ? roles : []}
          >
            {(item) => (
              <SelectItem key={item.id} value={item.id}>
                {item.attribute.name}
              </SelectItem>
            )}
          </Select>
        </div>
      </div>

      <div className="flex items-start gap-4 mt-[10px]">
        <Button
          size="sm"
          className="w-max bg-primary-500 text-white text-sm rounded-md py-[10px] px-[20px] shadow-[0px_2px_4px_0px_rgba(165, 163, 174, 0.30)] capitalize"
          onClick={addSubUserHandler}
        >
          Add
        </Button>

        <Button
          size="sm"
          className="w-max bg-secondary-200 text-secondary-500 text-sm rounded-md py-[10px] px-[20px] shadow-[0px_2px_4px_0px_rgba(165, 163, 174, 0.30)] capitalize"
          onClick={closeModal}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default AddSubUsersModal;
