import { Tab, Tabs } from "@nextui-org/react";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { tabs } from "../../util/tabs";

const MyTabBar = () => {
  const { pathname } = useLocation()
  const [activeTab, setActiveTab] = useState(pathname.split('/')[4]);

  const handleTabChange = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <Tabs
      aria-label="tabs"
      variant=""
      selectedKey={pathname.split('/')[4]}
      classNames={{
        tabList: "bg-transparent",
        tab: 'p-0',
        tabContent: ['text-[#A8AAAE] text-[15px]', 'group-data-[selected=true]:text-white'],
        cursor: ["w-full bg-primary-500 text-white"],
        panel: "hidden",
      }}
    >
      {tabs.map((tab) => (
        <Tab key={tab.id}>
          <Link className="flex items-center gap-[6px] p-4" to={tab.route} onClick={() => handleTabChange(tab.id)}>
            {activeTab === tab.id ? tab.activeIcon : tab.icon}
            <span>{tab.label}</span>
          </Link>
        </Tab>
      ))}
    </Tabs>
  );
};

export default MyTabBar;
